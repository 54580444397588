import React from "react";

const Footer = () => {
  const GetYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <div className="footer-logo center">
        <a className="logo" href="https://www.sage.com">
          <img
            src="https://www.sage.com/Areas/SageDotCom/img/sage-logo-green.svg"
            width="100%"
            height="100%"
            alt="Sage Logo"
          />
        </a>
      </div>
      <div className="footer-navigation center">
        <a
          href="https://www.facebook.com/Sage/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="sg-icon sg-icon-facebook" aria-label="facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/sageofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="sg-icon sg-icon-instagram" aria-label="instagram"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/sage-software/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="sg-icon sg-icon-linkedin" aria-label="linkedin"></i>
        </a>
        <a
          href="https://twitter.com/sagegroupplc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="sg-icon sg-icon-twitter" aria-label="twitter"></i>
        </a>
        <a
          href="https://www.youtube.com/@sage"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="sg-icon sg-icon-youtube" aria-label="youtube"></i>
        </a>
      </div>
      <hr />
      <div className="footer-navigation center">
        © Sage Group plc {GetYear()}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sage.com/en-gb/legal/"
        >
          Legal
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sage.com/en-gb/legal/privacy-and-cookies/"
        >
          Privacy Notice and Cookies
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sage.com/en-gb/gdpr/"
        >
          General data protection regulation
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sage.com/investors/corporate-governance/anti-slavery-and-human-trafficking-statement/"
        >
          Anti-slavery statement 2019
        </a>
      </div>
    </footer>
  );
};

export default Footer;
