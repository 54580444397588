import React, { useState } from "react";
import Heading from "carbon-react/lib/components/heading";
import Textbox from "carbon-react/lib/components/textbox";
import Button from "carbon-react/lib/components/button";
import { useFormik } from "formik";
import Form from "carbon-react/lib/components/form";
import {
  Card,
  CardRow,
  CardColumn,
} from "carbon-react/lib/components/card";
import { getLoggedInUser, updateUser } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import * as ValidationSchema from "../../components/Forms/validationSchema.mjs";
import * as maxLengths from "../../components/Forms/max-lengths"
import { cleanString } from "../../components/common/stringUtils";

const Profile = () => {
  const [edit, setEdit] = useState(false);
  const [errors, changeErrors] = useState(null);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const profileQuery = useQuery(
    "profile",
    async () => {
      let user = await getLoggedInUser(getAccessTokenSilently);
      return user;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const initialValues = {
    firstName: profileQuery.data?.firstName,
    lastName: profileQuery.data?.lastName,
    email: profileQuery.data?.email,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      const result = ValidationSchema.editProfileSchema.validate(values, {
        abortEarly: false,
      });
      changeErrors(result.error);
      values.id = profileQuery.data.id;
      values.identityId = profileQuery.data.identityId;
      values.phones = profileQuery.data.phones;
      if (result.error === undefined) {
        setEdit(false);
        await updateUser(getAccessTokenSilently, values);
        queryClient.invalidateQueries("profile");
      }
    },
  });
  return (
    <>
      <Heading title="Profile" />
      <Card>
        <CardRow>
          <Heading
            title="Your profile"
            subheader="This information is used for your App registry profile. It does not affect your Sage ID."
            divider={false}
            mt={2}
          />
        </CardRow>
        <CardRow>
          <CardColumn>
            <Form
              onSubmit={formik.handleSubmit}
              leftSideButtons={
                edit ? (
                  <Button
                    buttonType="tertiary"
                    destructive
                    onClick={() => {
                      setEdit(false);
                      formik.resetForm();
                      changeErrors(null);
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button buttonType="tertiary" onClick={() => setEdit(true)}>
                    Edit
                  </Button>
                )
              }
              saveButton={
                <Button type="submit" buttonType="primary">
                  Save
                </Button>
              }
            >
              <Textbox
                label="First name"
                labelInline
                labelWidth={22}
                readOnly={!edit}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                id="firstName"
                name="firstName"
                type="text"
                maxLength={maxLengths.firstName}
                required
                error={
                  !errors
                    ? null
                    : cleanString(
                        JSON.stringify(
                          errors.details.map((error) =>
                            error.context.key === "firstName"
                              ? error.message
                              : null
                          )
                        )
                      )
                }
              />

              <Textbox
                label="Last name"
                labelInline
                labelWidth={22}
                readOnly={!edit}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                id="lastName"
                name="lastName"
                type="text"
                maxLength={maxLengths.lastName}
                required
                error={
                  !errors
                    ? null
                    : cleanString(
                        JSON.stringify(
                          errors.details.map((error) =>
                            error.context.key === "lastName"
                              ? error.message
                              : null
                          )
                        )
                      )
                }
              />

              <Textbox
                label="Email address"
                labelInline
                labelWidth={22}
                readOnly={!edit}
                value={formik.values.email}
                onChange={formik.handleChange}
                id="email"
                name="email"
                type="email"
                maxLength={maxLengths.emailAddress}
                required
                error={
                  !errors
                    ? null
                    : cleanString(
                        JSON.stringify(
                          errors.details.map((error) =>
                            error.context.key === "email" ? error.message : null
                          )
                        )
                      )
                }
              />
            </Form>
          </CardColumn>
        </CardRow>
      </Card>
    </>
  );
};

export default Profile;
