import Joi from "joi";
import xss from "xss";
import { Roles } from "./Roles.js";

/**
 * Schema's for validating incoming requests. These also serve as documentation
 * of what expected payloads are for the relative methods. As this data can be
 * used to populate the UI, a custom '.sanitize()' extension method has been
 * added to Joi, to sanitize harmful, untrusted HTML sent in strings. If this
 * is detected, Joi will automatically escape the data, instead of failing the
 * request. This way the data is preserved, but stored XSS is mitigated.
 */

/**
 * Extend Joi to handle HTML Sanitization
 */

const joi = Joi.extend((joiRoot) => {
  return {
    type: "string",
    base: joiRoot.string(),
    rules: {
      sanitize: {
        validate(value) {
          return xss(value);
        },
      },
    },
  };
});

/**
 * Map Validation Errors Into Returnable Error Object
 * @param {*} validationErrors
 * @returns
 */

export const mapValidationErrors = (validationErrors) => {
  return validationErrors.details.map((error) => {
    return {
      code: "Validation.Error",
      source: error.context.key,
      data: error.message,
    };
  });
};

/**
 * Custom Validation Error Object
 * @param {*} message The error message
 * @param {*} errors The validationResult.errors object
 */

export class ValidationError extends Error {
  constructor(message, errors) {
    super(message);
    this.name = "ValidationError";
    this.errors = mapValidationErrors(errors);
  }
}

/**
 * Common Schema's
 */

const addressSchema = joi.object({
  street: joi.string().trim().sanitize().allow(""),
  city: joi.string().trim().sanitize().allow(""),
  province: joi.string().trim().sanitize().allow(""),
  postalCode: joi.string().trim().sanitize().allow(""),
  country: joi.string().length(2).uppercase().alphanum().allow(""),
});

const clientPropertySchema = joi
  .object({
    name: joi.string().trim().sanitize().required(),
    value: joi.any().required(),
  })
  .min(1)
  .required();


export const createDeveloperSchema = joi
  .object({
    id: joi.string().trim().sanitize().allow(""),
    name: joi.string().trim().sanitize().required(),
    supportEmail: joi
      .string()
      .trim()
      .sanitize()
      .email({ tlds: { allow: false } })
      .required(),
    address: addressSchema,
  })
  .unknown(true);

export const updateDeveloperSchema = joi.object({
  id: joi.string().trim().guid().required(),
  name: joi.string().trim().sanitize().required(),
  phones: joi.array().required(),
  supportEmail: joi
    .string()
    .trim()
    .sanitize()
    .email({ tlds: { allow: false } })
    .required(),
  address: addressSchema,
});

export const createDeveloperApplicationSchema = joi.object({
  id: joi.string().trim().allow(""),
  name: joi.string().trim().sanitize().required(),
  homepageUrl: joi
    .string()
    .uri({
      scheme: ["http", "https"],
    })
    .sanitize()
    .allow(""),
  supportEmail: joi
    .string()
    .trim()
    .sanitize()
    .email({ tlds: { allow: false } })
    .allow(""),
  apiClients: joi.array(),
});

export const updateDeveloperApplicationSchema = joi.object({
  id: joi.string().trim().guid().required(),
  name: joi.string().trim().sanitize().required(),
  homepageUrl: joi
    .string()
    .uri({
      scheme: ["http", "https"],
    })
    .sanitize()
    .allow(""),
  supportEmail: joi
    .string()
    .trim()
    .sanitize()
    .email({ tlds: { allow: false } })
    .allow(""),
  clients: joi.array().required(),
});

export const createMemberInvitationSchema = joi.object({
  firstName: joi.string().trim().sanitize().required(),
  lastName: joi.string().trim().sanitize().required(),
  email: joi
    .string()
    .trim()
    .sanitize()
    .email({ tlds: { allow: false } })
    .required(),
  culture: joi
    .string()
    .trim()
    .length(5)
    .regex(new RegExp("^[a-z]{2}-[A-Z]{2}$"))
    .allow(""),
  initialRole: joi
    .string()
    .trim()
    .valid(Roles.developerOwner, Roles.developerAdmin, Roles.developerUser)
    .required(),
});

export const editProfileSchema = joi.object({
  firstName: joi.string().trim().sanitize().required(),
  lastName: joi.string().trim().sanitize().required(),
  email: joi
    .string()
    .trim()
    .sanitize()
    .email({ tlds: { allow: false } })
    .required(),
});


export const createDeveloperApplicationClientSchema = joi.object({
  apiName: joi.string().trim().sanitize().required(),
  clientProperties: joi.array().items(clientPropertySchema).required(),
  isNew: joi.boolean().required(),
});

export const updateDeveloperApplicationClientSchema = joi.object({
  apiName: joi.string().trim().sanitize().required(),
  clientProperties: joi.array().items(clientPropertySchema).required(),
});

export const rotateDeveloperApplicationClientKeysSchema = joi.object({
  clientPropertyNames: joi
    .array()
    .items(joi.string().trim().sanitize().required()),
});