import React from "react";
import {
  Card as CarbonCard,
  CardRow,
  CardFooter,
} from "carbon-react/lib/components/card";
import Typography from "carbon-react/lib/components/typography";
import Box from "carbon-react/lib/components/box";
import Button from "carbon-react/lib/components/button";

function Card(props) {
  return (
    <CarbonCard
      key={props.id}
      cardWidth="225px"
      height="205px"
      spacing="small"
      marginLeft={0}
      marginTop={0}
      boxShadow="boxShadow300"
      hoverBoxShadow="boxShadow200"
      interactive
      action={props.onClickFunction}
    >
      <CardRow />
      <CardRow pt={2}>
        <Typography variant="segment-header" m={0} truncate>
          {props.title}
        </Typography>{" "}
      </CardRow>
      <CardRow pt={0}>
        <Typography fontSize="16px" m={0} truncate>
          {props.homepageUrl}
        </Typography>
      </CardRow>
      <CardRow pt={0}>
        <Typography fontSize="16px" m={0} truncate>
          {props.email}
        </Typography>
      </CardRow>

      {props.editFunction || props.deleteFunction ? (
        <CardFooter px={2} py={1}>
        <Box
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="left"
          padding={1}
        >
          {props.editFunction && (
            <Button
              mr={3}
              buttonType="tertiary"
              disabled={props.editDisabled}
              onClick={props.editFunction}
            >
              Edit
            </Button>
          )}
          {props.deleteFunction && (
            <Button
              ml={3}
              buttonType="tertiary"
              destructive
              onClick={props.deleteFunction}
              disabled={props.deleteDisabled}
            >
              Delete
            </Button>
          )}
        </Box>
      </CardFooter>) : 
      <CardRow />}
    </CarbonCard>
  );
}

export default Card;
