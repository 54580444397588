import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

function Home() {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/workspaces",
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate("/workspaces");
    }
    if (!isAuthenticated && !isLoading) {
      handleLogin();
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  return <div />;
}

export default Home;
