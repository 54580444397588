import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "carbon-react/lib/components/navigation-bar";
import { Menu, MenuItem } from "carbon-react/lib/components/menu";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { getUsers, getLoggedInUser } from "../api";
import { SessionContext } from "../context/SessionContext";
import { useNavigate, useParams } from "react-router";
import CreateProfile from "./Forms/CreateProfile";
import config from "../config";

const Header = () => {
  const [signUpIsOpen, setSignUpIsOpen] = useState(false);
  const navigate = useNavigate();
  const {developerId} = useParams();
  const { state: session, dispatch } = useContext(SessionContext);
  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/workspaces",
      },
    });
  };
  const handleLogout = async () => {
    await logout({
      returnTo: "https://developer.sage.com",
    });
  };
  const navigateToWorkspaces = () => {
    navigate("/workspaces");
  };
  const profileQuery = useQuery(
    "profile",
    async () => {
      let user = await getLoggedInUser(getAccessTokenSilently);

      return user;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  
  useEffect(() => {
    dispatch({ type: "SET_DEVELOPER", payload: developerId });}
    , [developerId, dispatch]);

  useEffect(() => {
    if (profileQuery.status === "success" && !profileQuery?.data?.id) {
      console.log("no profile");
      setSignUpIsOpen(true);
    }
    if (profileQuery.status === "success" && profileQuery?.data?.id) {
      setSignUpIsOpen(false);
    }
  }, [profileQuery]);

  const userQuery = useQuery(["users", session.developerId], async () => {
    if (session.developerId) {
      let users = await getUsers(getAccessTokenSilently, session.developerId);

      return users;
    }
  });

  useEffect(() => {
    if (userQuery.data && userQuery.status === "success" && user) {
      const userWithEmail = userQuery.data?.developerUsers.find(
        (users) => users.email === user.email
      );
      if (userWithEmail) {
        dispatch({ type: "SET_ROLE", payload: userWithEmail.role });
      }
    }
  }, [userQuery.status, userQuery.data, dispatch, user]);
  

  return (
    <div>
      <CreateProfile isOpen={signUpIsOpen} setIsOpen={setSignUpIsOpen} />
      <NavigationBar
        navigationType="black"
        position="fixed"
        orientation="top"
        aria-label="header"
        display="flex"
        isLoading={isLoading}
      >
        <Menu
          menuType="black"
          display="flex"
          flex="1"
          justifyContent="flex-start"
          onClick={navigateToWorkspaces}
          style={{ cursor: "pointer" }}
        >
          <img
            src="https://www.sage.com/Areas/SageDotCom/img/sage-logo-green.svg"
            alt="Sage"
            style={{ height: "26px", paddingTop: "10px", paddingRight: "16px" }}
          />

          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              paddingRight: "16px",
            }}
          >
            App Registry
          </div>
        </Menu>
        {config.env === "prod" ? (
          <></>
        ) : (
          <div
            style={{
              fontWeight: "bold",
              fontSize: "36px",
              color: "red",
            }}
          >{`You are in the ${config.env} environment`}</div>
        )}
        <Menu
          menuType="black"
          display="flex"
          flex="1"
          justifyContent="flex-end"
        >
          {isAuthenticated && (
            <>
              <MenuItem icon="person" ariaLabel="Account" submenu={user.name}>
                <MenuItem
                  icon="person"
                  ariaLabel="profile"
                  onClick={() => navigate("/profile")}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  icon="logout"
                  ariaLabel="sign out"
                  onClick={handleLogout}
                >
                  Sign Out
                </MenuItem>
              </MenuItem>
            </>
          )}

          {!isAuthenticated && (
            <>
              <MenuItem ariaLabel="sign in" onClick={handleLogin}>
                Sign In
              </MenuItem>
            </>
          )}
        </Menu>
      </NavigationBar>
    </div>
  );
};

export default Header;
