import React from "react";
import Box from "carbon-react/lib/components/box";

function CardLayout(props) {
  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="left"
        margin={0}
        padding="0px"
      >
        {props.children}
      </Box>
    </>
  );
}

export default CardLayout;
