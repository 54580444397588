import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getInvite, registerInvite } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import Heading from "carbon-react/lib/components/heading";
import Button from "carbon-react/lib/components/button";
import { useNavigate } from "react-router";

function Invitation() {
  const navigate = useNavigate();
  function getErrorDescription(inputObject) {
    try {
      const inputStr = inputObject.toString();
      const errorStart = inputStr.indexOf('{"errors":');
      const errorStr = inputStr.substring(errorStart);
      const errorObj = JSON.parse(errorStr);
      return errorObj.errors[0].description;
    } catch (error) {
      return "An unknown error occurred - retrying this operation may result in success";
    }
  }
  const { getAccessTokenSilently } = useAuth0();
  const { inviteId } = useParams();
  const [text, setText] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError("");
    setText("");
    const fetchData = async () => {
      try {
        const response = await getInvite(getAccessTokenSilently, inviteId);
        console.log("Response: " + response.developerName);
        setText(
          "You have been invited to join " +
            response.developerName +
            ". Click below to accept the invitation."
        );
      } catch (err) {
        console.log(err);
        setError(getErrorDescription(err));
      }
    };

    fetchData();
  }, [getAccessTokenSilently, inviteId]);

  const acceptInvite = async () => {
    try {
      await registerInvite(getAccessTokenSilently, inviteId);
      navigate("/workspaces");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Heading title="Invitation" />
      {text === "" ? (
        error
      ) : (
        <>
          {text} <br />
          <Button mt={3} buttonType="primary" onClick={acceptInvite}>
            Accept Invitation
          </Button>{" "}
        </>
      )}
    </>
  );
}

export default Invitation;
