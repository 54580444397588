import React from "react";
import SingleLineText from "./fields/SingleLineText";
import PickList from "./fields/PickList";

function Field(props) {
  return (
    <div>
      {props.presentation.element.elementType === "SingleLineText" && (
        <SingleLineText {...props} />
      )}
      {props.presentation.element.elementType === "PickList" && (
        <PickList {...props} />
      )}
    </div>
  );
}

function ClientField(props) {
  return (
    <div>
      {props.parameters.map((item, index) => {
        return (
          <div key={index}>
            <Field {...item} {...props} />
          </div>
        );
      })}
    </div>
  );
}

export default ClientField;
