import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import Textbox from "carbon-react/lib/components/textbox";
import * as ValidationSchema from "./validationSchema.mjs";
import { createApplication } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { SessionContext } from "../../context/SessionContext";
import * as maxLengths from "./max-lengths"
import { cleanStringUrl, cleanString } from "../common/stringUtils";

function CreateApp(props) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [errors, changeErrors] = useState(null);
  const { developerId } = useParams();

  const { state: session } = useContext(SessionContext);
  const formik = useFormik({
    initialValues: {
      name: "",
      homepageUrl: "",
      supportEmail: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const result = ValidationSchema.createDeveloperApplicationSchema.validate(
        values,
        { abortEarly: false }
      );
      changeErrors(result.error);

      if (result.error === undefined) {
        await createApplication(
          getAccessTokenSilently,
          developerId,
          values
        );

        queryClient.invalidateQueries(["applications", session.developerId]);

        props.setIsOpen(false);
        resetForm();
      }
    },
  });

  const cancelDialog = () => {
    formik.setErrors({});
    formik.resetForm();
    changeErrors(null)
    props.setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={cancelDialog}
        title="Create application"
      >
        <Form
          onSubmit={formik.handleSubmit}
          leftSideButtons={
            <Button
              buttonType="tertiary"
              onClick={cancelDialog}
            >
              Cancel
            </Button>
          }
          saveButton={
            <Button type="submit" buttonType="primary">
              Create
            </Button>
          }
        >
          <Textbox
            placeholder="My new app"
            label="Application name"
            id="name"
            name="name"
            type="text"
            maxLength={maxLengths.applicationName}
            onChange={formik.handleChange}
            value={formik.values.name}
            required={true}
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "name" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="https://mynewapp.com"
            fieldHelp="A webpage that you want to associate with your application"
            label="Homepage"
            id="homepageUrl"
            name="homepageUrl"
            type="text"
            maxLength={maxLengths.url}
            onChange={formik.handleChange}
            value={formik.values.homepageUrl}
            error={
              !errors
                ? ""
                : cleanStringUrl(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "homepageUrl"
                          ? 'Home Page URL is not in a valid format, must be: https://www.example.com'
                          : null
                      ).filter(item => item !== null)
                    )
                  )
              
            }
          />
          <Textbox
            placeholder="joe@example.com"
            fieldHelp="We will use this email to contact you regarding this application"
            label="Contact email address"
            id="supportEmail"
            name="supportEmail"
            type="email"
            maxLength={maxLengths.emailAddress}
            onChange={formik.handleChange}
            value={formik.values.supportEmail}
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "supportEmail"
                          ? error.message
                          : null
                      )
                    )
                  )
            }
          />
        </Form>
      </Dialog>
    </div>
  );
}

export default CreateApp;
