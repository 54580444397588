import React, { useContext, useEffect, useState } from "react";
import SideBar from "./SideBar";
import SideBarItem from "./SideBarItem";
import { SessionContext } from "../../context/SessionContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { getDevelopers } from "../../api";
import WorkspaceSelector from "./WorkspaceSelector";

function Bar(props) {
  const { state: session } = useContext(SessionContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [ currentDeveloper, setCurrentDeveloper ] = useState(null);

  const developersQuery = useQuery(
    ["developers", session.developerId],
    async () => {
      const developers = await getDevelopers(getAccessTokenSilently);
      return developers.developers;
    },
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (developersQuery.data) {
      setCurrentDeveloper(
        developersQuery.data.find(
          (developer) => developer.id === session.developerId
        )
      );
    }
  }, [session.developerId, developersQuery.data]);


  return (
  <>
    {currentDeveloper &&
      <SideBar
        setCollapse={(collapse) => props.setCollapse(collapse)}
        collapse={props.collapse}
      >
        {session.developerId ? (
          <>
            <WorkspaceSelector
              index="1"
              currentDeveloper={currentDeveloper}
              developersQuery={developersQuery}
              collapse={props.collapse}
              setCollapse={(collapse) => props.setCollapse(collapse)}
            />
            <SideBarItem
              index="2"
              icon="home"
              title="Workspace Details"
              navigate={`/workspaces/${session.developerId}`}
              collapse={props.collapse}
            />
            <SideBarItem
              index="3"
              icon="card_view"
              title="Apps"
              navigate={`/workspaces/${session.developerId}/applications`}
              collapse={props.collapse}
            />
            {currentDeveloper && currentDeveloper.showUsageButton && (
              <SideBarItem
                index="4"
                icon="chart_line"
                title="Usage"
                navigate={`/workspaces/${session.developerId}/transactions`}
                collapse={props.collapse}
              />
            )}
            <SideBarItem
              index="5"
              icon="people"
              title="Users"
              navigate={`/workspaces/${session.developerId}/users`}
              collapse={props.collapse}
            />
          </>
        ) : null}
      </SideBar>
    }
    </>
  );
}

export default Bar;
