import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
  getDeveloper,
  deleteDeveloper,
} from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { SessionContext } from "../../context/SessionContext";
import Header from "./Header";
import Details from "./Details";

const Developer = () => {
  const { state: session, dispatch } = useContext(SessionContext);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient(); 
  const { developerId } = useParams();

  const developerQuery = useQuery(["developer", developerId], () => {
    dispatch({ type: "SET_DEVELOPER", payload: developerId });
    
    return getDeveloper(getAccessTokenSilently, developerId);
  });

  return (
    <>
      {developerQuery.data && (
        <>
          <Header
            developerQuery={developerQuery}
            getAccessTokenSilently={getAccessTokenSilently}
            session={session}
            queryClient={queryClient}
            deleteDeveloper={deleteDeveloper}
          />
          <Details developerQuery={developerQuery} />
        </>
      )}
    </>
  );
};

export default Developer;
