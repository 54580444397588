import CarbonProvider from "carbon-react/lib/components/carbon-provider";
import sageTheme from "carbon-react/lib/style/themes/sage";
import GlobalStyle from "carbon-react/lib/style/global-style";
import { SessionStore } from "./context/SessionContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layouts/Layout";
import { Callback } from "./Callback";
import Developer from "./views/Developer/Developer";
import Application from "./views/Application";
import Transactions from "./views/Transactions";
import Users from "./views/Users";
import Profile from "./views/Profile/Profile";
import { AuthProvider } from "./AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Workspaces from "./views/Workspaces";
import Applications from "./views/Applications";
import Invitation from "./views/Invitation";
import Home from "./views/Home";

const queryClient = new QueryClient();

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component);
  return <Component />;
};

const router = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <Layout />
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/callback",
        element: <Callback />,
      },
      {
        path: "/profile",
        element: <AuthenticationGuard component={Profile} />,
      },
      {
        path: "/workspaces",
        element: <AuthenticationGuard component={Workspaces} />,
      },
      {
        path: "/workspaces/:developerId",
        element: <AuthenticationGuard component={Developer} />,
      },
      {
        path: "/workspaces/:developerId/transactions",
        element: <AuthenticationGuard component={Transactions} />,
      },
      {
        path: "/workspaces/:developerId/users",
        element: <AuthenticationGuard component={Users} />,
      },
      {
        path: "/workspaces/:developerId/applications",
        element: <AuthenticationGuard component={Applications} />,
      },
      {
        path: "/invitations/:inviteId",
        element: <AuthenticationGuard component={Invitation} />,
      },
      {
        path: "/workspaces/:developerId/applications/:applicationId",
        element: <AuthenticationGuard component={Application} />,
      },
    ],
  },
]);

const App = () => {
  return (
    <CarbonProvider theme={sageTheme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <SessionStore>
          <RouterProvider router={router} />
        </SessionStore>
      </QueryClientProvider>
    </CarbonProvider>
  );
};

export default App;
