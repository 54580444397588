import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import Textbox from "carbon-react/lib/components/textbox";
import * as ValidationSchema from "./validationSchema.mjs";
import { createUser } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { SessionContext } from "../../context/SessionContext";
import { userRoles } from "./Roles";
import { Select, Option } from "carbon-react/lib/components/select";
import * as maxLengths from "./max-lengths"
import { cleanString } from "../common/stringUtils";

function getErrorDescription(inputObject) {
  const inputStr = inputObject.toString();
  const errorStart = inputStr.indexOf('{"errors":');
  const errorStr = inputStr.substring(errorStart);
  const errorObj = JSON.parse(errorStr);
  return errorObj.errors[0].description;
}

function CreateUser(props) {
  //Need to add validation to make sure email being submitted is not already a user
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [errors, changeErrors] = useState(null);
  const [apiErrors, changeApiErrors] = useState(null);
  const { developerId, applicationId } = useParams();

  const { state: session, dispatch } = useContext(SessionContext);
  const [response, setResponse] = useState();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      culture: "",
      initialRole: "",
    },
    onSubmit: async (values, { resetForm }) => {
      //   let languageCode = navigator.language;
      //   if (languageCode.length === 2) {
      //     languageCode = languageCode.padEnd(5, "-ZZ"); //CHANGE TO GET COUNTRY CODE FROM DEVELOPER
      //   }
      //   console.log(languageCode);

      //   values.culture = languageCode;
      values.culture = "en-GB";
      const result = ValidationSchema.createMemberInvitationSchema.validate(
        values,
        { abortEarly: false }
      );
      changeErrors(result.error);
      if (result.error === undefined) {
        try {
          await createUser(getAccessTokenSilently, developerId, values);

          queryClient.invalidateQueries(["invites", developerId]);
          props.setIsOpen(false);
          resetForm();
        } catch (error) {
          try {
            changeApiErrors(getErrorDescription(error));
          } catch (error) {
            queryClient.invalidateQueries(["invites", developerId]);
            props.setIsOpen(false);
            resetForm();
          }
        }
      }
    },
  });

  const cancelDialog = () => {
    formik.setErrors({});
    formik.resetForm();
    changeErrors(null)
    props.setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={cancelDialog}
        title="Invite user"
      >
        <Form
          onSubmit={formik.handleSubmit}
          leftSideButtons={
            <Button
              buttonType="tertiary"
              onClick={cancelDialog}
            >
              Cancel
            </Button>
          }
          saveButton={
            <Button type="submit" buttonType="primary">
              Invite
            </Button>
          }
        >
          <div></div>
          <Textbox
            placeholder="First name"
            label="First name"
            id="firstName"
            name="firstName"
            type="text"
            maxLength={maxLengths.firstName}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            required={true}
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "firstName" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="Last name"
            label="Last name"
            id="lastName"
            name="lastName"
            type="text"
            maxLength={maxLengths.lastName}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            required={true}
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "lastName" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="joe@example.com"
            label="Email"
            id="email"
            name="email"
            type="email"
            maxLength={maxLengths.emailAddress}
            onChange={formik.handleChange}
            value={formik.values.email}
            required={true}
            error={
              !errors
                ? !apiErrors
                  ? ""
                  : apiErrors
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "email" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Select
            label="Role"
            id="initialRole"
            name="initialRole"
            type="text"
            onChange={formik.handleChange}
            required={true}
            labelHelp={
              <>
                Owner: Can create or delete the workspace, change contact
                details, manage users and manage apps inside of the workspace.
                <br />
                Admin: May not delete the workspace or change contact details.
                <br />
                User: Can view all workspace and app details.
              </>
            }
            error={
              !errors
                ? null
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "initialRole"
                          ? error.message
                          : null
                      )
                    )
                  )
            }
          >
            {userRoles.map((roles) => (
              <Option key={roles.id} text={roles.name} value={roles.id} />
            ))}
          </Select>
        </Form>
      </Dialog>
    </div>
  );
}

export default CreateUser;
