import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./config";

const { domain, clientId, audience } = config;

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    
    const onRedirectCallback = appState => {
        navigate("/workspaces");
    };


    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={`${window.location.origin}`}
            audience={audience}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};