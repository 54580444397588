import { Select, Option } from "carbon-react/lib/components/select";
import React, { useState } from "react";
import { addErrorToList, removeErrorFromList } from "./errorUtils";

function PickList(props) {
  const presentation = props.presentation;
  const { displayName, element, required, description, acceptForCreate, acceptForUpdate } = presentation;
  const [error, setError] = useState(null);

  const validateInputAndUpdateErrors = (selectedValue) => {
    if (isInputInvalid(selectedValue)) {
      addErrorToList(props.name, props.errors, props.setErrors);
    } else {
      removeErrorFromList(props.name, props.errors, props.setErrors, setError);
    }
  };

  const isInputInvalid = (selectedValue) => {
    if (!required) return false;
    if (!selectedValue) {
      setError(`${displayName} is required`);
      return true;
    }
    return false;
  };

  let readOnly = false;

  if (props.create) {
    if (!acceptForCreate) {
      readOnly = true;
    }
  } else if (props.update) {
    if (!acceptForUpdate) {
      readOnly = true;
    }
  }

  return (
    <div>
      <Select
        label={displayName}
        id={props.name}
        name={props.name}
        readOnly={readOnly}
        onChange={(e) => {
          props.formik.handleChange(e);
          validateInputAndUpdateErrors(e.target.value);
        }}
        required={required}
        error={error}
        labelHelp={description}
        value={props.formik.values[props.name] || ''}
      >
        {element.items.map((item) => (
          <Option key={item.index} value={item.value} text={item.label} />
        ))}
      </Select>
    </div>
  );
}

export default PickList;
