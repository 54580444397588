export const addErrorToList = (name, errors, setErrors) => {
  if (!errors.includes(name)) {
    setErrors([...errors, name]);
  }
};

export const removeErrorFromList = (name, errors, setErrors, setError) => {
  setError(null);
  if (errors.includes(name)) {
    setErrors(errors.filter((error) => error !== name));
  }
};
