import React from "react";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

const Row = styled.li`
  width: auto;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
`;

const SmallRow = styled.li`
  width: 60px;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${Row}:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
  &:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
`;
const IconBox = styled.div`
  flex: 30%;
  display: grid;
  place-items: center;
`;

const TitleBox = styled.div`
  flex: 70%;
`;

function SideBarItem(props) {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <>
      {props.collapse ? (
        <Row
          key={props.index}
          id={location.pathname === props.navigate ? "active" : ""}
          onClick={() => {
            navigate(props.navigate);
          }}
        >
          {" "}
          <IconBox>
            <Icon type={props.icon} />
          </IconBox>
          <TitleBox>{props.title}</TitleBox>
        </Row>
      ) : (
        <SmallRow
          key={props.index}
          id={location.pathname === props.navigate ? "active" : ""}
          onClick={() => {
            navigate(props.navigate);
          }}
        >
          {" "}
          <IconBox>
            <Icon type={props.icon} />
          </IconBox>
        </SmallRow>
      )}
    </>
  );
}

export default SideBarItem;
