import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import { FormikProvider, useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import ClientField from "../apiclient/ClientField";
import { updateApiClient } from "../../api";
import Message from "carbon-react/lib/components/message";
import Loader from "carbon-react/lib/components/loader";
import { getErrorDescription } from "../common/errorManagement";

function AddApi(props) {
  const [errors, setErrors] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    apiName: "",
  };

  useEffect(() => {
    parameters.forEach((item) => {
      initialValues[item.name] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  function transformFormData(formData) {
    const transformedData = {
      apiName: props.apiToEdit.name,
      clientProperties: [],
    };

    for (const key in formData) {
      if (key !== "apiName") {
        transformedData.clientProperties.push({
          name: key,
          value: formData[key],
        });
      }
    }

    transformedData.isNew = true;

    return transformedData;
  }
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { developerId, applicationId } = useParams();

  useEffect(() => {
    parameters.forEach((item) => {
      initialValues[item.name] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  for (const key in props.editValues) {
    initialValues[props.editValues[key].name] = props.editValues[key].value;
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
        setLoading(true);
        setApiError(null);
        try {
          const data = transformFormData(values);
          console.log(data);
          await updateApiClient(
            getAccessTokenSilently,
            developerId,
            applicationId,
            data
          );

          props.setIsOpen(false);
          resetForm();
          setLoading(false);
          queryClient.invalidateQueries(["clients", developerId, applicationId]);
        } catch (error) {
          setApiError(getErrorDescription(error.toString()));
          setLoading(false);
        }
    },
  });

  const cancelDialog = () => {
    props.setIsOpen(false);
    setErrors([]);
    setLoading(false);
    setApiError(null);
    setParameters([]);
    formik.setFieldValue("apiName", "");
    formik.setFieldValue("clientProperties", []);
    formik.resetForm();
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={() => cancelDialog()}
        title="Edit API key"
        subtitle={props.apiToEdit?.displayName}
      >
        <FormikProvider value={formik}>
          <Form
            onSubmit={formik.handleSubmit}
            leftSideButtons={
              <Button buttonType="tertiary" onClick={() => cancelDialog()}>
                Cancel
              </Button>
            }
            saveButton={
              <Button type="submit" buttonType="primary">
                Save
              </Button>
            }
            errorCount={errors.length}
          >
            {props.apiToEdit && (
              <ClientField
                parameters={props.apiToEdit.parameters}
                formik={formik}
                errors={errors}
                setErrors={setErrors}
                update
                apiToEdit={props.apiToEdit}
                setLoading={(loading) => setLoading(loading)}
                setIsOpen={props.setIsOpen}
                setApiError={setApiError}
              />
            )}
            {loading && (
              <>
                <br />
                <Loader />
              </>
            )}
            {apiError && (
              <Message showCloseIcon={false} open variant="error">
                {apiError}
              </Message>
            )}
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}

export default AddApi;
