export const Roles = {
  developerUser: 'developerUser',
	developerAdmin: 'developerAdmin',
	developerOwner: 'developerOwner',
};

export const getRole = (role) => {
	switch (role) {
		case Roles.developerUser:
			return "Workspace User";
		case Roles.developerAdmin:
			return "Workspace Admin";
		case Roles.developerOwner:
			return "Workspace Owner";
		default:
			return "Standard";
	}
}

export const userRoles = [
	{ id: Roles.developerUser, name: getRole(Roles.developerUser) },
	{ id: Roles.developerAdmin, name: getRole(Roles.developerAdmin) },
	{ id: Roles.developerOwner, name: getRole(Roles.developerOwner) }
];