import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import Textbox from "carbon-react/lib/components/textbox";
import { setDeveloperUserRole } from "../../api.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { userRoles } from "./Roles";
import { Select, Option } from "carbon-react/lib/components/select";
import * as maxLengths from "./max-lengths"
import { cleanString } from "../common/stringUtils";

function EditUser(props) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [errors, changeErrors] = useState(null);
  const { developerId } = useParams();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      culture: props.user.culture,
      initialRole: props.user.initialRole,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await setDeveloperUserRole(
          getAccessTokenSilently,
          developerId,
          props.user.id,
          values.initialRole
        );
      } catch (error) {
        console.log(error);
      }
      queryClient.invalidateQueries(["users", developerId]);
      props.setIsOpen(false);
      resetForm();
    },
  });

  const cancelDialog = () => {
    formik.setErrors({});
    formik.resetForm();
    changeErrors(null)
    props.setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={cancelDialog}
        title="Change role"
      >
        <Form
          onSubmit={formik.handleSubmit}
          leftSideButtons={
            <Button
              buttonType="tertiary"
              onClick={cancelDialog}
            >
              Cancel
            </Button>
          }
          saveButton={
            <Button type="submit" buttonType="primary">
              Save
            </Button>
          }
        >
          <Textbox
            placeholder="First name"
            label="First name"
            id="firstName"
            name="firstName"
            type="text"
            maxLength={maxLengths.firstName}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            required={true}
            readOnly
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "firstName" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="Last name"
            label="Last name"
            id="lastName"
            name="lastName"
            type="text"
            maxLength={maxLengths.lastName}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            required={true}
            readOnly
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "lastName" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="DeveloperOrg@live.com"
            label="Email"
            id="email"
            name="email"
            type="email"
            maxLength={maxLengths.emailAddress}
            onChange={formik.handleChange}
            value={formik.values.email}
            required={true}
            readOnly
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "email" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Select
            label="Role"
            id="initialRole"
            name="initialRole"
            type="text"
            onChange={formik.handleChange}
            required={true}
            error={
              !errors
                ? null
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "initialRole"
                          ? error.message
                          : null
                      )
                    )
                  )
            }
          >
            {userRoles.map((roles) => (
              <Option key={roles.id} text={roles.name} value={roles.id} />
            ))}
          </Select>
        </Form>
      </Dialog>
    </div>
  );
}

export default EditUser;
