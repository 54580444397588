import React, { useContext, useState } from "react";
import {
    Card,
    CardRow,
    CardFooter,
    CardColumn
} from "carbon-react/lib/components/card";
import Typography from "carbon-react/lib/components/typography";
import Box from "carbon-react/lib/components/box";
import Button from "carbon-react/lib/components/button";
import IconButton from "carbon-react/lib/components/icon-button";
import { ActionPopover, ActionPopoverItem, } from "carbon-react/lib/components/action-popover";
import { Roles } from "../components/Forms/Roles";
import { useNavigate, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteApplication } from "../api";
import { SessionContext } from "../context/SessionContext";
import ConfirmBox from "./ConfirmBox";
import Loader from "carbon-react/lib/components/loader";
import Icon from "carbon-react/lib/components/icon";

const AppDetailsCard = (props) => {
  const { developerId, applicationId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [errorMessage, setErrorMessage] = useState("");

  const [displayConfirmationMessage, setDisplayConfirmationMessage] =
    useState(false);
  const navigate = useNavigate();

  const { state: session } = useContext(SessionContext);

  const OnConfirm = async () => {
    try {
      await deleteApplication(
        getAccessTokenSilently,
        developerId,
        applicationId
      );
      navigate(`/workspaces/${developerId}`);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const userCanEditOrDeleteApp = () => {
    return (
      session &&
      (session.role === Roles.developerOwner ||
        session.role === Roles.developerAdmin)
    );
  };

  const copyIdToClipboard = () => {
    navigator.clipboard
      .writeText(props.id)
      .then(() => {
        console.log("Text copied to clipboard.");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  const maxWidth="320px"

  return (
    <>
      <Card
        key={props.id}
        cardWidth="350px"
        spacing="small"
        marginLeft={0}
        marginTop={0}
        boxShadow="boxShadow300"
        hoverBoxShadow="boxShadow200"
      >
        <CardRow>
          <CardColumn align="left" mb={16} pb={16}>
            <CardRow py={2}>
              <Typography variant="segment-header" m={0} truncate style={{maxWidth:maxWidth}}>
                {props.title || "Loading..."}
              </Typography>{" "}
            </CardRow>
            <CardRow py={0}>
              <Typography variant="segment-subheader" m={0} truncate style={{maxWidth:maxWidth}}>
                {props.homepageUrl}
              </Typography>
            </CardRow>
            <CardRow py={0}>
              <Typography fontSize="16px" m={0} color="grey" truncate style={{maxWidth:maxWidth}}>
                {props.email}
              </Typography>
            </CardRow>{" "}
            <CardRow py={0}>
              <Typography fontSize="16px" m={0} color="grey" truncate style={{maxWidth:maxWidth}}>
                {props.id || (
                  <CardColumn align="center" mt={16}>
                    <Loader />
                  </CardColumn>
                )}
              </Typography>
                {
                  props.id &&
                  <IconButton aria-label="icon-button" onClick={copyIdToClipboard}>
                    <Icon type="copy" />
                  </IconButton>
                }
            </CardRow>
          </CardColumn>

          {userCanEditOrDeleteApp() && (
            <CardColumn align="right">
              <IconButton
                aria-label="icon-button"
                marginTop="16px"
              >
                <ActionPopover>
                  <ActionPopoverItem
                    onClick={() => setDisplayConfirmationMessage(true)}
                    icon="delete"
                  >
                    Delete
                  </ActionPopoverItem>
                </ActionPopover>{" "}
              </IconButton>
            </CardColumn>
          )}
        </CardRow>

        <CardFooter px={2} py={1}>
          <Box
            width="100%"
            alignItems="center"
            display="flex"
            justifyContent="left"
            padding={0}
          >
            <Button mr={3} buttonType="tertiary" onClick={props.editFunction}>
              Edit
            </Button>
          </Box>
        </CardFooter>
      </Card>

      <ConfirmBox
        title="Deleting this App?"
        subtitle="Are you sure you want to delete this App?"
        onConfirm={OnConfirm}
        onCancel={() => {
          setDisplayConfirmationMessage(false);
          setErrorMessage("");
        }}
        open={displayConfirmationMessage}
        error={errorMessage}
        confirmButtonDestructive
      >
        Are you sure you want to delete this App?
      </ConfirmBox>
    </>
  );
};

export default AppDetailsCard;