export const getErrorDescription = (inputStr) => {
  let errorMessage = ''; 
		const defaultError = 'An unknown error occurred - retrying this operation may result in success';
    const errorStartPosition = inputStr.indexOf('{"errors":');
    const errorObject = JSON.parse(inputStr.substring(errorStartPosition));

    if (Array.isArray(errorObject.errors)) {
      errorObject.errors.forEach(error => {
        if (error.source && error.code)
        {
          errorMessage += `Error returned from API : Type : ${error.code}. Source : ${error.source}. `;
        }        
        if (error.description) {
          errorMessage += `${error.description}. `;
        }
      });
      
      if (errorMessage === '')
      {
        errorMessage = defaultError;
      }
    } else {
      errorMessage = defaultError;
    }
		
		return errorMessage;
};
