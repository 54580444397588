// Default max lengths for text fields

export const firstName = 40;
export const lastName = 80;
export const emailAddress = 80;
export const workspaceName = 100;
export const country = 120;
export const street = 255;
export const townCity = 40;
export const stateProvinceRegion = 80;
export const postCodeZipCode = 20;
export const applicationName = 100;
export const url = 255;
export const apiName = 255;
export const apiValue = 1000;
export const applicationId = 36;