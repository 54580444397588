import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getDeveloper, deleteApplication, getApplications } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import { SessionContext } from "../context/SessionContext";
import Typography from "carbon-react/lib/components/typography";
import CardLayout from "../components/Layouts/CardLayout";
import Card from "../components/Card";
import CreateApp from "../components/Forms/CreateApp";
import {
  Card as CarbonCard,
  CardRow,
  CardFooter,
  CardColumn,
} from "carbon-react/lib/components/card";
import Icon from "carbon-react/lib/components/icon";
import Box from "carbon-react/lib/components/box";
import Button from "carbon-react/lib/components/button";
import image from "./Untitled.png";
import Heading from "carbon-react/lib/components/heading";
import ConfirmBox from "../components/ConfirmBox";
import { Roles } from "../components/Forms/Roles";
import EditApp from "../components/Forms/EditApp";
import Loader from "carbon-react/lib/components/loader";

const Applications = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [appToDelete, setAppToDelete] = useState(null);
  const [appIsOpen, setAppIsOpen] = useState(false);
  const { state: session } = useContext(SessionContext);

  const { getAccessTokenSilently } = useAuth0();

  const queryClient = useQueryClient();

  const { developerId } = useParams();

  const navigate = useNavigate();

  async function callDeleteApplicationEndpoint() {
    try {
      await deleteApplication(
        getAccessTokenSilently,
        developerId,
        appToDelete.id
      );
      queryClient.invalidateQueries(["applications", developerId]);
      setConfirmDelete(false);
    } catch (error) {
      setError(error);
    }
    return;
  }

  const developerQuery = useQuery(["developer", developerId], () => {
    return getDeveloper(getAccessTokenSilently, developerId);
  });

  const applicationsQuery = useQuery(
    ["applications", developerId],
    async () => {
      let applications = await getApplications(
        getAccessTokenSilently,
        developerId
      );

      return applications.applications;
    },
    {
      enabled: !!developerId,
      refetchOnWindowFocus: false,
    }
  );

  const openAppApiKeys = (applicationId) => {
    navigate(`/workspaces/${developerId}/applications/${applicationId}`);
  };

  const [editApp, setEditApp] = useState(false);
  const [error, setError] = useState("");
  const [editAppValues, setEditAppValues] = useState({
    name: "",
    supportEmail: "",
    id: "",
    homepageUrl: "",
    clients: [],
  });

  return (
    <>
      {developerQuery.data && (
        <>
          <ConfirmBox
            title="Delete Application"
            open={confirmDelete}
            confirmButtonDestructive
            onConfirm={() => {
              callDeleteApplicationEndpoint();
            }}
            onCancel={() => {
              setConfirmDelete(false);
              setError("");
            }}
            error={error}
          >
            Are you sure you want to delete this application?
          </ConfirmBox>
          <EditApp
            application={editAppValues}
            setIsOpen={(editApp) => setEditApp(editApp)}
            isOpen={editApp}
          />
          <Heading title="Applications" />
          {applicationsQuery.isLoading ? (
            <Loader size="large" mt={10} />
          ) : !applicationsQuery.isLoading &&
            applicationsQuery.data.length > 0 ? (
            <CardLayout>
              {applicationsQuery.data &&
                applicationsQuery.data?.map((application) => (
                  <Card
                    key={application.id}
                    id={application.id}
                    title={application.name}
                    homepageUrl={application.homepageUrl}
                    email={application.supportEmail}
                    editFunction={(e) => {
                      e.stopPropagation();
                      setEditAppValues(application);
                      setEditApp(true);
                    }}
                    deleteFunction={(e) => {
                      e.stopPropagation();
                      setConfirmDelete(true);
                      setAppToDelete(application);
                    }}
                    onClickFunction={() => {
                      openAppApiKeys(application.id);
                    }}
                    editDisabled={
                      session.role === Roles.developerOwner ||
                      session.role === Roles.developerAdmin
                        ? false
                        : true
                    }
                    deleteDisabled={
                      session.role === Roles.developerOwner ||
                      session.role === Roles.developerAdmin
                        ? false
                        : true
                    }
                  />
                ))}
              <CarbonCard
                key={"defaultKey"}
                cardWidth="225px"
                height="205px"
                spacing="small"
                marginLeft={0}
                marginTop={0}
                alignItems="center"
                display="flex"
                justifyContent="center"
                boxShadow="boxShadow300"
                hoverBoxShadow="boxShadow200"
              >
                <CardRow />
                <CardRow>
                  <CardColumn>
                    {" "}
                    <Typography variant="segment-header" mx={3}>
                      Create a new application
                    </Typography>{" "}
                  </CardColumn>
                </CardRow>{" "}
                <CardFooter px={2} py={1}>
                  <Box
                    width="100%"
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    padding={1}
                  >
                    <CreateApp
                      getAccessTokenSilently={getAccessTokenSilently}
                      setIsOpen={(appIsOpen) => setAppIsOpen(appIsOpen)}
                      isOpen={appIsOpen}
                    />
                    <Button
                      buttonType="primary"
                      onClick={() => setAppIsOpen(true)}
                      disabled={
                        session.role === Roles.developerOwner ||
                        session.role === Roles.developerAdmin
                          ? false
                          : true
                      }
                    >
                      <Icon type="add" />
                      Add application
                    </Button>
                  </Box>
                </CardFooter>
              </CarbonCard>
            </CardLayout>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="stretch"
              m="0"
            >
              <Box
                width="175px"
                height="175px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                m="0"
              >
                <img width="120px" height="120px" src={image} alt="Sage" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="stretch"
                m="0"
              >
                <Box width="auto" height="auto">
                  <Typography variant="segment-header" m={1}>
                    This dashboard is empty
                  </Typography>
                  <Typography m={1}>Start by adding an application</Typography>
                </Box>
                <Box width="auto" height="auto">
                  <Button
                    mt={2}
                    buttonType="primary"
                    ml={2}
                    onClick={() => setAppIsOpen(true)}
                    disabled={
                      session.role === Roles.developerOwner ||
                      session.role === Roles.developerAdmin
                        ? false
                        : true
                    }
                  >
                    <Icon type="add" />
                    Add application
                  </Button>
                  <CreateApp
                    getAccessTokenSilently={getAccessTokenSilently}
                    setIsOpen={(appIsOpen) => setAppIsOpen(appIsOpen)}
                    isOpen={appIsOpen}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Applications;
