import React, { useContext, useState } from "react";
import Heading from "carbon-react/lib/components/heading";
import Typography from "carbon-react/lib/components/typography";
import {
  Card as CarbonCard,
  CardRow,
  CardFooter,
  CardColumn,
} from "carbon-react/lib/components/card";
import Box from "carbon-react/lib/components/box";
import Button from "carbon-react/lib/components/button";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "react-query";
import { getDevelopers, deleteDeveloper } from "../api";
import { useNavigate } from "react-router-dom";
import Icon from "carbon-react/lib/components/icon";
import CreateDeveloper from "../components/Forms/CreateDeveloper";
import CardLayout from "../components/Layouts/CardLayout";
import Card from "../components/Card";
import image from "./Untitled.png";
import EditDeveloper from "../components/Forms/EditDeveloper";
import ConfirmBox from "../components/ConfirmBox";
import { SessionContext } from "../context/SessionContext";
import Loader from "carbon-react/lib/components/loader"

const Workspaces = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(SessionContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [workspaceToDelete, setWorkspaceToDelete] = useState({});
  const [editWorkspace, setEditWorkspace] = useState(false);
  const [editWorkspaceValues, setEditWorkspaceValues] = useState({});

  const developersQuery = useQuery(
    ["developers"],
    async () => {
      dispatch({ type: "SET_DEVELOPER", payload: null });
      const developers = await getDevelopers(getAccessTokenSilently);
      return developers.developers;
    },
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();
  const [companyIsOpen, setCompanyIsOpen] = useState(false);
  const [error, setError] = useState("");

  async function callDeleteWorkspaceEndpoint() {
    try {
      await deleteDeveloper(getAccessTokenSilently, workspaceToDelete);
      await queryClient.invalidateQueries(["developers"]);
      setConfirmDelete(false);
    } catch (error) {
      setError(error);
    }
  }

  const openWorkspace = (workspaceId) => {
    navigate(`/workspaces/${workspaceId}`);
  };

  return (
    <>
      <ConfirmBox
        title="Delete Workspace"
        open={confirmDelete}
        confirmButtonDestructive
        onConfirm={() => {
          callDeleteWorkspaceEndpoint();
        }}
        onCancel={() => {
          setConfirmDelete(false);
          setError("");
        }}
        error={error}
      >
        Are you sure you want to delete this workspace?
      </ConfirmBox>
      <CreateDeveloper
        setIsOpen={(companyIsOpen) => setCompanyIsOpen(companyIsOpen)}
        isOpen={companyIsOpen}
      />
      <EditDeveloper
        developer={editWorkspaceValues}
        setIsOpen={(editWorkspace) => setEditWorkspace(editWorkspace)}
        isOpen={editWorkspace}
      />
      <Heading title="Workspaces" />
      {developersQuery.isLoading ? (<Loader size="large" mt={10}/>) :
      !developersQuery.isLoading && developersQuery.data?.length > 0 ? (
        <CardLayout>
          {developersQuery.data &&
            developersQuery.data.map((developer) => (
              <Card
                key={developer.id}
                id={developer.id}
                title={developer.name}
                email={developer.supportEmail}                
                onClickFunction={() => {
                  openWorkspace(developer.id);
                }}
              />
            ))}
          <CarbonCard
            key={"defaultKey"}
            cardWidth="225px"
            height="205px"
            spacing="small"
            marginLeft={0}
            marginTop={0}
            alignItems="center"
            display="flex"
            justifyContent="center"
            boxShadow="boxShadow300"
            hoverBoxShadow="boxShadow200"
          >
            <CardRow />
            <CardRow>
              <CardColumn>
                {" "}
                <Typography variant="segment-header" mx={3}>
                  Create a new workspace
                </Typography>{" "}
              </CardColumn>
            </CardRow>{" "}
            <CardFooter px={2} py={1}>
              <Box
                width="100%"
                alignItems="center"
                display="flex"
                justifyContent="center"
                padding={1}
              >
                <Button
                  buttonType="primary"
                  onClick={() => setCompanyIsOpen(true)}
                >
                  <Icon type="add" />
                  Add workspace
                </Button>
              </Box>
            </CardFooter>
          </CarbonCard>
        </CardLayout>
      ) : (
        <div>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="stretch"
            m="0"
          >
            <Box
              width="175px"
              height="175px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              m="0"
            >
              <img width="120px" height="120px" src={image} alt="Sage" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="stretch"
              m="0"
            >
              <Box width="auto" height="auto">
                <Typography variant="segment-header" m={1}>
                  This dashboard is empty
                </Typography>
                <Typography m={1}>Start by creating a workspace</Typography>
              </Box>
              <Box width="auto" height="auto">
                <Button
                  mt={2}
                  buttonType="primary"
                  ml={2}
                  onClick={() => setCompanyIsOpen(true)}
                >
                  <Icon type="add" />
                  Add workspace
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default Workspaces;
