import React, { useState, useContext } from "react";
import Heading from "carbon-react/lib/components/heading/";
import Button from "carbon-react/lib/components/button";
import { useNavigate } from "react-router";
import { deleteDeveloper } from "../../api";
import { SessionContext } from "../../context/SessionContext";
import { Roles } from "../../components/Forms/Roles";
import ConfirmBox from "../../components/ConfirmBox";

function Header(props) {
  const { state: session } = useContext(SessionContext);
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState("");

  const removeDeveloper = async () => {
    setError("");
    try {
      await deleteDeveloper(
        props.getAccessTokenSilently,
        props.session.developerId
      );
      await props.queryClient.invalidateQueries(["developers"]);
      setConfirm(false);
      navigate(`/workspaces`);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <>
      <ConfirmBox
        open={confirm}
        confirmButtonDestructive
        onConfirm={() => {
          removeDeveloper();
        }}
        onCancel={() => {
          setConfirm(false);
          setError("");
        }}
        title="Delete Workspace"
        error={error}
      >
        Are you sure you want to delete this workspace?
      </ConfirmBox>
      <Heading
        title={props.developerQuery.data.name}
        pills={
          <Button
            size="small"
            buttonType="tertiary"
            destructive
            iconType="delete"
            iconPosition="after"
            onClick={() => setConfirm(true)}
            disabled={session.role === Roles.developerOwner ? false : true}
          ></Button>
        }
      />
    </>
  );
}

export default Header;
