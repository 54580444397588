import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import Textbox from "carbon-react/lib/components/textbox";
import * as ValidationSchema from "./validationSchema.mjs";
import { createDeveloper } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import * as maxLengths from "./max-lengths"
import { cleanString } from "../common/stringUtils";

function CreateDeveloper(props) {
  const { dispatch } = useContext(SessionContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [errors, changeErrors] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      supportEmail: "",
      address: {
        street: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
      },
    },
    onSubmit: async (values, { resetForm }) => {
      const result = ValidationSchema.createDeveloperSchema.validate(values, {
        abortEarly: false,
      });
      changeErrors(result.error);

      if (result.error === undefined) {
        const newDeveloper = await createDeveloper(
          getAccessTokenSilently,
          values
        );
        
        resetForm();
        props.setIsOpen(false);
        dispatch({ type: "SET_DEVELOPER", payload: newDeveloper.id });
    
        queryClient.invalidateQueries(["developers"]).then(() => {
          navigate(`/workspaces/${newDeveloper.id}`);
        });
      }
    },
  });

  const cancelDialog = () => {
    formik.setErrors({});
    formik.resetForm();
    changeErrors(null)
    props.setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={cancelDialog}
        title="Create workspace"
      >
        <Form
          onSubmit={formik.handleSubmit}
          leftSideButtons={
            <Button
              buttonType="tertiary"
              onClick={cancelDialog}
            >
              Cancel
            </Button>
          }
          saveButton={
            <Button type="submit" buttonType="primary">
              Create
            </Button>
          }
        >
          <Textbox
            placeholder="Workspace, organization or business name"
            label="Workspace name"
            id="name"
            name="name"
            type="text"
            maxLength={maxLengths.workspaceName}
            onChange={formik.handleChange}
            value={formik.values.name}
            normalize={(value) => (value === "" ? "" : value)}
            required={true}
            error={
              !errors
                ? null
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "name" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="joe@example.com"
            fieldHelp="We will use this email to contact you regarding this workspace"
            label="Contact email address"
            id="supportEmail"
            name="supportEmail"
            type="email"
            maxLength={maxLengths.emailAddress}
            onChange={formik.handleChange}
            value={formik.values.supportEmail}
            normalize={(value) => (value === "" ? "" : value)}
            required={true}
            error={
              !errors
                ? null
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "supportEmail"
                          ? error.message
                          : null
                      )
                    )
                  )
            }
          />
        </Form>
      </Dialog>
    </div>
  );
}

export default CreateDeveloper;