import Textbox from "carbon-react/lib/components/textbox";
import React, { useState } from "react";
import Button from "carbon-react/lib/components/button";
import { addErrorToList, removeErrorFromList } from './errorUtils';
import Toast from "carbon-react/lib/components/toast";
import { rotateApiClientSecrets } from "../../../api";
import { useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import * as maxLengths from "../../Forms/max-lengths"
import { getErrorDescription } from "../../common/errorManagement";

function SingleLineText(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { developerId, applicationId } = useParams();
  const queryClient = useQueryClient();
  const presentation = props.presentation;
  const { displayName, element, required, canBeRotated, acceptForUpdate } = presentation;
  const { hiddenByDefault, expression, hint } = element;
  const [hidden, setHidden] = useState(hiddenByDefault);
  const [error, setError] = useState(null);
  const [hiddenClicked, setHiddenClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  
  if (presentation.defaultValue) {
    props.formik.values[props.name] = presentation.defaultValue
  }

  async function rotateSecret() {
    props.setLoading(true);
    try {
      await rotateApiClientSecrets(
        getAccessTokenSilently,
        developerId,
        applicationId,
        props.apiToEdit.name,
        props.name
      );
      props.setIsOpen(false);
    } catch (error) {
      const errorMessage = getErrorDescription(error.toString());
      props.setApiError(errorMessage);
      console.log("Error: ", error);
    }
    queryClient.invalidateQueries(["clients", developerId, applicationId]);
    props.setLoading(false);
  }
  

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(props.formik.values[props.name])
      .then(() => {
        console.log("Text copied to clipboard.");
        handleToggle();
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  const validateInputAndUpdateErrors = (input) => {
    if (isInputInvalid(input.target.value)) {
      addErrorToList(props.name, props.errors, props.setErrors);
    } else {
      removeErrorFromList(props.name, props.errors, props.setErrors, setError);
    }
  };
  const isInputInvalid = (inputValue) => {
    const regex = expression ? new RegExp(expression) : null;

    let isInvalid = false;
    let errorMessage = "";

    if (required && regex) {
      isInvalid = !regex.test(inputValue);
      errorMessage = hint || `${displayName} is required`;
    } else if (required && inputValue === "") {
      isInvalid = true;
      errorMessage = `${displayName} is required`;
    } else if (!required && regex && inputValue !== "") {
      isInvalid = !regex.test(inputValue);
      errorMessage = hint;
    }

    if (isInvalid) setError(errorMessage);
    return isInvalid;
  };

  function Helpers() {
    return (
      <>
        {hiddenByDefault && (
          <Button
            onClick={() => {
              setHidden(!hidden);
              setHiddenClicked(true);
            }}
            iconType={hidden ? "view" : "hide"}
            buttonType="tertiary"
            size="small"
          />
        )}
        {canBeRotated && (
          <Button
            onClick={() => rotateSecret()}
            iconType={"refresh"}
            buttonType="tertiary"
            size="small"
          />
        )}
        {!acceptForUpdate && (
          <Button
            onClick={copyToClipboard}
            iconType="copy"
            buttonType="tertiary"
            size="small"
          />
        )}
      </>
    );
  }

  const onDismissClick = () => {
    if (!isOpen) {
      window.scrollTo(0, 0);
    }
    setIsOpen(!isOpen);
  };
  const handleToggle = () => {
    if (!isOpen) {
      window.scrollTo(0, 0);
    }
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Toast
        variant="success"
        id="toast-dismissible"
        open={isOpen}
        onDismiss={onDismissClick}
        isCenter
        timeout={2000}
      >
        Copied
      </Toast>
      {((presentation.acceptForCreate && props.create) ||
      (presentation.acceptForUpdate &&
        presentation.displayForUpdate &&
        props.update)) && !presentation.defaultValue ? ( //editable textbox
        <Textbox
          id={props.name}
          name={props.name}
          label={presentation.displayName}
          labelHelp={presentation.description}
          required={presentation.required}
          onChange={(e) => {
            props.formik.handleChange(e);
            validateInputAndUpdateErrors(e);
          }}
          maxLength={maxLengths.apiValue}
          value={props.formik.values[props.name]}
          type={
            hiddenClicked
              ? hidden
                ? "password"
                : "text"
              : hiddenByDefault
              ? "password"
              : "text"
          }
          error={error}
        >
          <Helpers />
        </Textbox>
      ) : (
        <></>
      )}
      {(props.update &&
      presentation.displayForUpdate &&
      !presentation.acceptForUpdate) ||
      (presentation.defaultValue) ? ( // read only textbox
        <Textbox
          name={props.name}
          id={props.name}
          value={props.formik.values[props.name]}
          label={presentation.displayName}
          labelHelp={presentation.description}
          required={presentation.required}
          type={
            hiddenClicked
              ? hidden
                ? "password"
                : "text"
              : hiddenByDefault
              ? "password"
              : "text"
          }
          readOnly
        >
          <Helpers />
        </Textbox>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SingleLineText;
