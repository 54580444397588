const local = {
  env: "local",
  clientId: "Qyb99woDPErfCOS1N4j2x9gdNJ4x7SB6",
  audience: "f7e805e/devx",
  domain: "id-shadow.sage.com",
  apiUrl: "https://devx-dev2-ecomm2dev-app.azurewebsites.net",
};

const dev2 = {
  env: "dev2",
  clientId: "Qyb99woDPErfCOS1N4j2x9gdNJ4x7SB6",
  audience: "f7e805e/devx",
  domain: "id-shadow.sage.com",
  apiUrl: "https://devx-dev2-ecomm2dev-app.azurewebsites.net",
};

const rc1 = {
  env: "rc1",
  apiUrl: "https://appregistryapi-developer.sagedatacloud.com",
  clientId: "1e6uLY7tLrSMqObdLQktTaZ6S6PdH6va",
  audience: "pdo/devx",
  domain: "id-shadow.sage.com",
};

const prod = {
  env: "prod",
  apiUrl: "https://devx-prod.sagedatacloud.com",
  clientId: "9LPXYNpH3pZiSXaSIurVD2TDOIRCWbfl",
  audience: "sfc/appregistry",
  domain: "id.sage.com"
};

let config;

switch (process.env.REACT_APP_STAGE) {
  case "dev2": {
    config = dev2;
    break;
  }

  case "rc1": {
    config = rc1;
    break;
  }

  case "prod": {
    config = prod;
    break;
  }

  default: {
    config = local;
  }
}

let configExport = { ...config };

export default configExport;
