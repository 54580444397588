import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { embedPowerBi } from "../api";
import Heading from "carbon-react/lib/components/heading";
import "../App.css";
import Message from "carbon-react/lib/components/message";

const Transactions = () => {
  const { developerId } = useParams();

  const { getAccessTokenSilently } = useAuth0();

  const [report, setReport] = useState(null);
  const [apiError, setApiError] = useState(null);

  const powerBiQuery = useQuery(
    [getAccessTokenSilently, "powerBi", developerId],
    async () => {
      return embedPowerBi(getAccessTokenSilently, developerId);
    },
    { retry: false }
  );

  useEffect(() => {
    if (powerBiQuery.isError && powerBiQuery.error.errors && powerBiQuery.error.errors.length > 0) {
      setApiError(powerBiQuery.error.errors[0].description);
    }
  }, [powerBiQuery.isError, powerBiQuery.error]);

  return (
    <>
      <Heading title="API Transactions" />
      {apiError && (
        <Message showCloseIcon={false} open variant="error">
          {apiError}
        </Message>
      )}
      {powerBiQuery.data && (
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: powerBiQuery.data.id,
            embedUrl: powerBiQuery.data.embedUrl,
            accessToken: powerBiQuery.data.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          cssClassName={"transactionsClass"}
          getEmbeddedComponent={(embeddedReport) => {
            setReport(embeddedReport);
          }}
        />
      )}
    </>
  );
};

export default Transactions;