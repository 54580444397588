import React from "react";
import Confirm from "carbon-react/lib/components/confirm";
import Message from "carbon-react/lib/components/message";

function ConfirmBox(props) {
  function getErrorDescription(inputObject) {
    try {
      const inputStr = inputObject.toString();
      const errorStart = inputStr.indexOf('{"errors":');
      const errorStr = inputStr.substring(errorStart);
      const errorObj = JSON.parse(errorStr);
      return errorObj.errors[0].description;
    } catch (error) {
      return "An unknown error occurred - retrying this operation may result in success";
    }
  }
  return (
    <Confirm
      open={props.open}
      confirmButtonDestructive={props.confirmButtonDestructive}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      title={props.title}
      disableConfirm={props.error === "" ? false : true}
    >
      {props.children}
      {props.error === "" ? (
        <></>
      ) : (
        <>
          <br />
          <br />
          <Message
            open={props.error === "" ? false : true}
            showCloseIcon={false}
            variant="error"
          >
            {getErrorDescription(props.error)}
          </Message>
        </>
      )}
    </Confirm>
  );
}

export default ConfirmBox;
