import React, { useState } from "react";
import { Card, CardRow, CardColumn } from "carbon-react/lib/components/card";
import Typography from "carbon-react/lib/components/typography";
import Icon from "carbon-react/lib/components/icon";
import UpdateDeveloper from "../../components/Forms/EditDeveloper";

const DetailsCard = (props) => {
  const [editDeveloper, setEditDeveloper] = useState(false);
  return (
    <>
      <UpdateDeveloper
        developer={props.developer}
        setIsOpen={(editDeveloper) => setEditDeveloper(editDeveloper)}
        isOpen={editDeveloper}
      />
      <Card
        key={props.developer.id}
        cardWidth="225px"
        spacing="small"
        marginLeft={0}
        marginTop={0}
        boxShadow="boxShadow300"
        hoverBoxShadow="boxShadow200"
        interactive={props.editable}
        action={props.editable ? () => setEditDeveloper(true) : null}
      >
        <CardRow>
          <CardColumn align="left" mb={16} pb={16}>
            <CardRow pt={2} pb={0}>
              <Typography variant="segment-header" m={0} truncate style={{maxWidth:'200px'}}>
                {props.developer.name}
              </Typography>{" "}
            </CardRow>
            <CardRow py={2}>
              <Typography fontSize="16px" m={0} color="grey" truncate  style={{maxWidth:'225px'}}>
                {props.developer.supportEmail}
              </Typography>
            </CardRow>
          </CardColumn>
          <CardColumn align="right">
            {props.editable ? <Icon marginTop="16px" type="edit" /> : null}
          </CardColumn>
        </CardRow>
      </Card>
    </>
  );
};

export default DetailsCard;
