import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Heading from "carbon-react/lib/components/heading";
import { SessionContext } from "../context/SessionContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getInvitations,
  getUsers,
  deleteInvite,
  deleteUser,
  createUser,
} from "../api";
import Icon from "carbon-react/lib/components/icon";
import Box from "carbon-react/lib/components/box";
import Button from "carbon-react/lib/components/button";
import {
  ActionPopover,
  ActionPopoverItem,
} from "carbon-react/lib/components/action-popover";
import {
  FlatTable,
  FlatTableHead,
  FlatTableBody,
  FlatTableRow,
  FlatTableHeader,
  FlatTableCell,
} from "carbon-react/lib/components/flat-table";
import CreateUser from "../components/Forms/CreateUser";
import EditUser from "../components/Forms/EditUser";
import ConfirmBox from "../components/ConfirmBox";
import { Roles, getRole } from "../components/Forms/Roles";

const Users = () => {
  console.log("Users()");
  const queryClient = useQueryClient();
  const [isConfirmDeleteInviteOpen, setIsConfirmDeleteInviteOpen] =
    useState(false);
  const [isConfirmDeleteUserOpen, setIsConfirmDeleteUserOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const { developerId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { state: session } = useContext(SessionContext);

  const userQuery = useQuery(["users", developerId], async () => {
    let users = await getUsers(getAccessTokenSilently, developerId);

    return users;
  });

  const inviteQuery = useQuery(["invites", developerId], async () => {
    let invites = await getInvitations(getAccessTokenSilently, developerId);

    return invites;
  });

  const [userIsOpen, setUserIsOpen] = useState(false);
  const [editUserIsOpen, setEditUserIsOpen] = useState(false);
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    culture: "",
    initialRole: "",
  });

  const MsInADay = 24 * 60 * 60 * 1000;

  const CalcDays = (timeInMs) => Math.floor(timeInMs / MsInADay);
  const GetDaysLeft = (expiryDate) => {
    let todayMs = Date.now();
    let expiryMs = Date.parse(expiryDate);
    let daysLeft = CalcDays(expiryMs - todayMs);

    if (daysLeft === 0) {
      return " (Invitation expires today) ";
    }

    if (daysLeft < 0) {
      return " (Invitation has expired) ";
    }

    if (daysLeft === 1) {
      return " (Invitation expires in " + daysLeft.toString() + " day)";
    }

    return " (Invitation expires in " + daysLeft.toString() + " days)";
  };
  const [deleteInviteError, setDeleteInviteError] = useState("")
  const [deleteUserError, setDeleteUserError] = useState("")

  async function callDeleteInvitationEndpoint() {
    try {
      await deleteInvite(getAccessTokenSilently, userToDelete);
    } catch (error) {
      console.log("Error: ", error);
      setDeleteInviteError(error)
      return;
    }
    queryClient.invalidateQueries(["invites", developerId]);
    setIsConfirmDeleteInviteOpen(false);
    return;
  }

  async function callDeleteUserEndpoint() {
    try {
      await deleteUser(getAccessTokenSilently, developerId, userToDelete.id);
    } catch (error) {
      console.log("Error: ", error);
      setDeleteUserError(error)
      return;
    }
    queryClient.invalidateQueries(["users", developerId]);
    setIsConfirmDeleteUserOpen(false);
    return;
  }

  async function callCreateUserEndpoint(user) {
    const invitation = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      culture: "en-GB",
      initialRole: user.initialRole,
    };
    try {
      await createUser(getAccessTokenSilently, developerId, invitation);
    } catch (error) {
      console.log("Error: ", error);
    }
    queryClient.invalidateQueries(["invites", developerId]);
  }

  return (
    <>
      <ConfirmBox
        title="Delete Invitation"
        open={isConfirmDeleteInviteOpen}
        confirmButtonDestructive
        onConfirm={() => {
          callDeleteInvitationEndpoint();
        }}
        onCancel={() => setIsConfirmDeleteInviteOpen(false)}
        error={deleteInviteError}
      >
        Are you sure you want to delete this invitation?
      </ConfirmBox>
      <ConfirmBox
        title="Delete User"
        open={isConfirmDeleteUserOpen}
        confirmButtonDestructive
        onConfirm={() => {
          callDeleteUserEndpoint();
        }}
        onCancel={() => setIsConfirmDeleteUserOpen(false)}
        error={deleteUserError}
      >
        Are you sure you want to delete this user?
      </ConfirmBox>
      <CreateUser
        getAccessTokenSilently={getAccessTokenSilently}
        setIsOpen={(userIsOpen) => setUserIsOpen(userIsOpen)}
        isOpen={userIsOpen}
      />
      <EditUser
        getAccessTokenSilently={getAccessTokenSilently}
        setIsOpen={(editUserIsOpen) => setEditUserIsOpen(editUserIsOpen)}
        isOpen={editUserIsOpen}
        user={editUser}
      />
      <Heading title="Users" />
      <Box padding={0} width="100%">
        <FlatTable width="100%" colorTheme="light">
          <FlatTableHead>
            <FlatTableRow>
              <FlatTableHeader>Name</FlatTableHeader>
              <FlatTableHeader>Email</FlatTableHeader>
              <FlatTableHeader>Role</FlatTableHeader>
              <FlatTableHeader px={1}>
                <Icon color="black" type="settings" />
              </FlatTableHeader>
            </FlatTableRow>
          </FlatTableHead>
          <FlatTableBody>
            <>
              {userQuery?.data?.developerUsers &&
                userQuery.data.developerUsers.map((user) => (
                  <FlatTableRow key={user.id}>
                    <FlatTableCell>
                      {user.firstName + " " + user.lastName}
                    </FlatTableCell>
                    <FlatTableCell>{user.email}</FlatTableCell>
                    <FlatTableCell>
                      {user.role === Roles.developerOwner
                        ? getRole(Roles.developerOwner)
                        : user.role === Roles.developerAdmin
                        ? getRole(Roles.developerAdmin)
                        : user.role === Roles.developerUser
                        ? getRole(Roles.developerUser)
                        : ""}
                    </FlatTableCell>
                    <FlatTableCell px={1}>
                      <ActionPopover>
                        <ActionPopoverItem
                          onClick={() => {
                            setEditUserIsOpen(true);
                            setEditUser(user);
                          }}
                          icon="edit"
                          disabled={
                            session.role === Roles.developerOwner ||
                            session.role === Roles.developerAdmin
                              ? false
                              : true
                          }
                        >
                          Change role
                        </ActionPopoverItem>
                        <ActionPopoverItem
                          onClick={() => {
                            setIsConfirmDeleteUserOpen(true);
                            setUserToDelete(user);
                          }}
                          icon="delete"
                          disabled={
                            session.role === Roles.developerOwner ||
                            session.role === Roles.developerAdmin
                              ? false
                              : true
                          }
                        >
                          Delete
                        </ActionPopoverItem>
                      </ActionPopover>
                    </FlatTableCell>
                  </FlatTableRow>
                ))}
              {inviteQuery?.data?.invitations &&
                inviteQuery.data.invitations.map((user) => (
                  <FlatTableRow key={user.id} bgColor="#D3D3D3">
                    <FlatTableCell>
                      {user.firstName +
                        " " +
                        user.lastName +
                        GetDaysLeft(user.expiry)}
                    </FlatTableCell>
                    <FlatTableCell>{user.email}</FlatTableCell>
                    <FlatTableCell>
                      {user.initialRole === Roles.developerOwner
                        ? getRole(Roles.developerOwner)
                        : user.initialRole === Roles.developerAdmin
                        ? getRole(Roles.developerAdmin)
                        : user.initialRole === Roles.developerUser
                        ? getRole(Roles.developerUser)
                        : ""}
                    </FlatTableCell>
                    <FlatTableCell px={1}>
                      <ActionPopover>
                        <ActionPopoverItem
                          onClick={() => {
                            callCreateUserEndpoint(user);
                          }}
                          icon="refresh_clock"
                          disabled={
                            session.role === Roles.developerOwner ||
                            session.role === Roles.developerAdmin
                              ? false
                              : true
                          }
                        >
                          Renew Invitation
                        </ActionPopoverItem>
                        <ActionPopoverItem
                          onClick={() => {
                            setIsConfirmDeleteInviteOpen(true);
                            setUserToDelete(user.id);
                          }}
                          icon="delete"
                          disabled={
                            session.role === Roles.developerOwner ||
                            session.role === Roles.developerAdmin
                              ? false
                              : true
                          }
                        >
                          Revoke Invitation
                        </ActionPopoverItem>
                      </ActionPopover>
                    </FlatTableCell>
                  </FlatTableRow>
                ))}
            </>
          </FlatTableBody>
        </FlatTable>
      </Box>
      <Button
        buttonType="primary"
        marginTop={3}
        onClick={() => setUserIsOpen(true)}
        disabled={
          session.role === Roles.developerOwner ||
          session.role === Roles.developerAdmin
            ? false
            : true
        }
      >
        Invite user
      </Button>
    </>
  );
};

export default Users;
