import React, { useState } from "react";

import Heading from "carbon-react/lib/components/heading";
import AppDetailsCard from "../components/AppDetailsCard";
import APIKeysCard from "../components/APIKeysCard";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getApis,
  getApplicationClients,
  getApplications,
} from "../api";
import EditApp from "../components/Forms/EditApp";
import { useParams } from "react-router";
import AddApi from "../components/Forms/AddApi";
import Loader from "carbon-react/lib/components/loader";
import { Card } from "carbon-react/lib/components/card";

const Application = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { developerId } = useParams();
  const { applicationId } = useParams();

  const [isApiDialogOpen, setIsApiDialogOpen] = useState(false);
  const [editApp, setEditApp] = useState(false);
  const [editAppValues, setEditAppValues] = useState({
    name: "",
    supportEmail: "",
    id: "",
    homepageUrl: "",
    clients: [],
  });

  const [currentApplication, setCurrentApplication] = useState(null);

  // apps the developer has
  useQuery(
    ["applications", developerId],
    async () => {
      let applications = await getApplications(
        getAccessTokenSilently,
        developerId
      );

      setCurrentApplication(
        applications.applications.find(
          (application) => application.id === applicationId
        )
      );

      return applications.applications;
    },
    {
      enabled: !!developerId,
      refetchOnWindowFocus: false,
    }
  );

  // lists all available apis and their parameters
  const apisQuery = useQuery(
    ["apis", developerId],
    async () => {
      let apis = await getApis(getAccessTokenSilently, developerId);

      return apis.apis;
    },
    {
      enabled: !!developerId,
      refetchOnWindowFocus: false,
    }
  );

  // Lists the apis the developer has and its fields
  const apisClientsQuery = useQuery(
    ["clients", developerId, applicationId],
    async () => {
      let clients = await getApplicationClients(
        getAccessTokenSilently,
        developerId,
        applicationId
      );

      return clients.clients;
    },
    {
      enabled: !!developerId,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Heading title="Application Details" />
      {currentApplication ? (
        <AppDetailsCard
          editFunction={() => {
            setEditAppValues(currentApplication);
            setEditApp(true);
          }}
          key={currentApplication.id}
          id={currentApplication.id}
          title={currentApplication.name}
          homepageUrl={currentApplication.homepageUrl}
          email={currentApplication.supportEmail}
          selectedApis={apisClientsQuery.data}
        />
      ) : (
        <AppDetailsCard>
          <Loader />
        </AppDetailsCard>
      )}

      <APIKeysCard
        application={editAppValues}
        developerId={developerId}
        applicationId={applicationId}
        apisClients={apisClientsQuery.data}
        apis={apisQuery.data}
        setIsApiDialogOpen={setIsApiDialogOpen}
      />

      <EditApp
        application={editAppValues}
        setIsOpen={(editApp) => setEditApp(editApp)}
        isOpen={editApp}
      />

      {!apisClientsQuery.isLoading &&
        !apisQuery.isLoading &&
        apisClientsQuery.data && (
          <AddApi
            apis={apisQuery.data}
            selectedApiClients={apisClientsQuery.data}
            setIsOpen={(api) => setIsApiDialogOpen(api)}
            isOpen={isApiDialogOpen}
          />
        )}
    </>
  );
};

export default Application;
