import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Dialog from "carbon-react/lib/components/dialog";
import { Option, FilterableSelect } from "carbon-react/lib/components/select";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import { FormikProvider, useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import ClientField from "../apiclient/ClientField";
import { updateApiClient } from "../../api";
import Message from "carbon-react/lib/components/message";
import Loader from "carbon-react/lib/components/loader";
import { getErrorDescription } from "../common/errorManagement";

function AddApi(props) {
  const [errors, setErrors] = useState([]);
  const [parameters, setParameters] = useState([]);
  const apis = props.apis;
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { developerId, applicationId } = useParams();

  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  const selectedApiNames = props.selectedApiClients.map(
    (client) => client.apiName
  );
  const initialValues = {
    apiName: "",
  };

  useEffect(() => {
    parameters.forEach((item) => {
      initialValues[item.name] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  function transformFormData(formData) {
    const transformedData = {
      apiName: formData.apiName,
      clientProperties: [],
    };

    for (const key in formData) {
      if (key !== "apiName") {
        transformedData.clientProperties.push({
          name: key,
          value: formData[key],
        });
      }
    }

    transformedData.isNew = true;

    return transformedData;
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setApiError(null);
      const isFieldMissing = checkRequiredFields(values);
      if (isFieldMissing) {
        setApiError("Please fill in all required fields");
        setLoading(false);
        return;
      }

      if (errors.length > 0) {
        setLoading(false);
        return;
      }

      try {
        const data = transformFormData(values);
        await updateApiClient(
          getAccessTokenSilently,
          developerId,
          applicationId,
          data
        );

        props.setIsOpen(false);
        resetForm();
        setLoading(false);
        queryClient.invalidateQueries(["clients", developerId, applicationId]);
        queryClient.invalidateQueries(["developers"]);
      } catch (error) {
        setApiError(getErrorDescription(error.toString()));
        setLoading(false);
      }
    },
  });

  const checkRequiredFields = (values) => {
    let isFieldMissing = false;
    parameters.forEach((parameter) => {
      if (parameter.presentation.required && !values[parameter.name]) {
        isFieldMissing = true;
      }
    });
    return isFieldMissing;
  };

  const handleChange = (selectedApi) => {
    const value = selectedApi.target.value;
    const matchedApi = apis.find((api) => api.name === value);

    if (value && matchedApi) {
      formik.resetForm();
      formik.setFieldValue("apiName", value);
      setParameters(apis.find((api) => api.name === value).parameters);
      setErrors([]);
      setApiError(null);
    }
  };

  const cancelDialog = () => {
    props.setIsOpen(false);
    setErrors([]);
    setLoading(false);
    setApiError(null);
    setParameters([]);
    formik.setFieldValue("apiName", "");
    formik.setFieldValue("clientProperties", []);
    formik.resetForm();
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={() => cancelDialog()}
        title="Add API key"
      >
        <FilterableSelect
          key="apiName"
          id="apiName"
          name="apiName"
          type="text"
          onChange={handleChange}
          label="Sage Product API"
          labelHelp="The API for the product you wish to connect to"
          placeholder="Choose a Sage product API"
        >
          {apis
            ?.filter((api) => !selectedApiNames.includes(api.name))
            .map((api) => {
              return (
                <Option
                  value={api.name}
                  text={api.displayName}
                  key={api.name}
                />
              );
            })}
        </FilterableSelect>
        <br />
        <FormikProvider value={formik}>
          <Form
            onSubmit={formik.handleSubmit}
            leftSideButtons={
              <Button buttonType="tertiary" onClick={() => cancelDialog()}>
                Cancel
              </Button>
            }
            saveButton={
              <Button type="submit" buttonType="primary">
                Create
              </Button>
            }
            errorCount={errors.length}
          >
            <ClientField
              parameters={parameters}
              formik={formik}
              errors={errors}
              setErrors={setErrors}
              create
            />
            {loading && (
              <>
                <br />
                <Loader />
              </>
            )}
            {apiError && (
              <Message showCloseIcon={false} open variant="error">
                {apiError}
              </Message>
            )}
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}

export default AddApi;
