import config from "./config";

const VERSION = "v1";
// Use environment specific values or default to local
const PREFIX = `${config.apiUrl}`;

const API_URL = `${PREFIX}/api/${VERSION}`;

export const getDevelopers = async (getAccessTokenSilently) => {
  console.log("API::getDevelopers()");

  let response = await fetch(
    `${API_URL}/developers?pageSize=100`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const getDeveloper = async (getAccessTokenSilently, developerId) => {
  console.log("getDeveloper()", developerId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const createDeveloper = async (getAccessTokenSilently, developer) => {
  console.log("createDeveloper()", developer);

  let response = await fetch(`${API_URL}/developers`, {
    method: "POST",
    body: JSON.stringify(developer),
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const updateDeveloper = async (
  getAccessTokenSilently,
  developerId,
  developer
) => {
  console.log("updateDeveloper()", developer);

  let response = await fetch(`${API_URL}/developers/${developerId}`, {
    method: "PUT",
    body: JSON.stringify(developer),
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const deleteDeveloper = async (getAccessTokenSilently, developerId) => {
  console.log("deleteDeveloper()", developerId);

  let response = await fetch(`${API_URL}/developers/${developerId}`, {
    method: "DELETE",
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }
};

export const getInvite = async (getAccessTokenSilently, invitationId) => {
  console.log("getInvite()", invitationId);

  let response = await fetch(`${API_URL}/invitations/${invitationId}`, {
    method: "GET",
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const registerInvite = async (getAccessTokenSilently, invitationId) => {
  console.log("registerInvite()", invitationId);

  let response = await fetch(`${API_URL}/invitations/${invitationId}`, {
    method: "PUT",
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }
};

export const deleteInvite = async (getAccessTokenSilently, invitationId) => {
  console.log("deleteInvite()", invitationId);

  let response = await fetch(`${API_URL}/invitations/${invitationId}`, {
    method: "DELETE",
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }
};

export const deleteUser = async (
  getAccessTokenSilently,
  developerId,
  userId
) => {
  console.log("deleteUser()", userId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/users/${userId}`,
    {
      method: "DELETE",
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }
};

export const getTransactions = async (getAccessTokenSilently, developerId) => {
  console.log("getTransactions()", developerId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/usage`,
    await getOptions(getAccessTokenSilently)
  );

  const token = response.json();

  console.log(JSON.stringify(token, null, 2));

  return token;
};

export const getApplications = async (getAccessTokenSilently, developerId) => {
  console.log("getApplications()", developerId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications?expand=clients`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const createApplication = async (
  getAccessTokenSilently,
  developerId,
  application
) => {
  console.log("createApplication()", application);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications`,
    {
      method: "POST",
      body: JSON.stringify(application),
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const updateApplication = async (
  getAccessTokenSilently,
  developerId,
  applicationId,
  application
) => {
  console.log("updateApplication()", application);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications/${applicationId}`,
    {
      method: "PUT",
      body: JSON.stringify(application),
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const deleteApplication = async (
  getAccessTokenSilently,
  developerId,
  applicationId
) => {
  console.log("deleteApplication()", applicationId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications/${applicationId}`,
    {
      method: "DELETE",
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }
};

export const getApplicationClients = async (
  getAccessTokenSilently,
  developerId,
  applicationId
) => {
  console.log("getApplicationClients()", applicationId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications/${applicationId}/clients`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const embedPowerBi = async (getAccessTokenSilently, developerId) => {
  console.log("embedPowerBi()", developerId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/usage`,
    await getOptions(getAccessTokenSilently)
  );

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error();
    error.errors = errorData.errors;
    throw error;
  }

  return response.json();
};


export const testEmbedPowerBi = async () => {
  console.log("testEmbedPowerBi()");

  let response = await fetch(
    `https://powerbiembedpoc20230112154910.azurewebsites.net/api/GetEmbedToken?developerId=1abd172b-c7e1-490d-9461-264f37b72cec`,
    {
      headers: {},
    }
  );

  return response.json();
};

export const getUsers = async (getAccessTokenSilently, developerId) => {
  console.log("getUsers()", developerId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/users`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const getInvitations = async (getAccessTokenSilently, developerId) => {
  console.log("getInvitations()", developerId);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/users/invitations`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const createUser = async (getAccessTokenSilently, developerId, user) => {
  console.log("createUser()", user);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/users/invitations`,
    {
      method: "POST",
      body: JSON.stringify(user),
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const getApis = async (getAccessTokenSilently, developerId) => {
  console.log("getApis()", developerId);

  let response = await fetch(
    `${API_URL}/metadata/apis?developerId=${developerId}`,
    await getOptions(getAccessTokenSilently)
  );

  return response.json();
};

export const updateApiClient = async (
  getAccessTokenSilently,
  developerId,
  applicationId,
  apiClient
) => {
  console.log("updateApiClient()", developerId, applicationId, apiClient);
  console.log("apiClient", apiClient);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications/${applicationId}/clients/${apiClient.apiName}`,
    {
      method: "PUT",
      body: JSON.stringify(apiClient),
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.json();
};

export const deleteApiClient = async (
  getAccessTokenSilently,
  developerId,
  applicationId,
  apiName
) => {
  console.log("deleteApiClient()", developerId, applicationId, apiName);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications/${applicationId}/clients/${apiName}`,
    {
      method: "DELETE",
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response;
};

export const rotateApiClientSecrets = async (
  getAccessTokenSilently,
  developerId,
  applicationId,
  apiName,
  propertyNamesToRotate
) => {
  console.log(
    "rotateApiClientSecrets()",
    developerId,
    applicationId,
    apiName,
    propertyNamesToRotate
  );

  const rotateRequest = {
    clientPropertyNames: [propertyNamesToRotate],
  };

  let response = await fetch(
    `${API_URL}/developers/${developerId}/applications/${applicationId}/clients/${apiName}/rotate`,
    {
      method: "PUT",
      body: JSON.stringify(rotateRequest),
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.json();
};

export const setDeveloperUserRole = async (
  getAccessTokenSilently,
  developerId,
  userId,
  role
) => {
  console.log("setDeveloperUserRole()", developerId, userId, role);

  let response = await fetch(
    `${API_URL}/developers/${developerId}/users/${userId}/setrole`,
    {
      method: "PUT",
      body: JSON.stringify({ role: role }),
      ...(await getOptions(getAccessTokenSilently)),
    }
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const getLoggedInUser = async (getAccessTokenSilently) => {
  console.log("getLoggedInUser()");

  let response = await fetch(
    `${API_URL}/users/fromtoken`,
    await getOptions(getAccessTokenSilently)
  );

  return await response.json();
};

export const updateUser = async (getAccessTokenSilently, user) => {
  console.log("updateUser()", user);

  let response = await fetch(`${API_URL}/users/${user.id}`, {
    method: "PUT",
    body: JSON.stringify(user),
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

export const createProfile = async (getAccessTokenSilently, profile) => {
  console.log("createProfile()", profile);

  let response = await fetch(`${API_URL}/users`, {
    method: "POST",
    body: JSON.stringify(profile),
    ...(await getOptions(getAccessTokenSilently)),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
};

async function getOptions(getAccessTokenSilently, timeout) {
  let defaultTimeoutMs = 15000;

  if (timeout !== undefined) {
    defaultTimeoutMs = timeout;
  }

  let headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${await getAccessTokenSilently()}`,
  };

  return {
    headers,
    timeout: defaultTimeoutMs,
  };
}
