import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import Textbox from "carbon-react/lib/components/textbox";
import * as ValidationSchema from "./validationSchema.mjs";
import { createProfile } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { SessionContext } from "../../context/SessionContext";
import * as maxLengths from "./max-lengths"
import { cleanString } from "../common/stringUtils";

function CreateProfile(props) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const [errors, changeErrors] = useState(null);
  const { developerId } = useParams();

  const { state: session } = useContext(SessionContext);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user ? user.given_name || "" : "",
      lastName: user ? user.family_name || "" : "",
      email: user ? user.email || "" : "",
    },
    onSubmit: async (values, { resetForm }) => {
      const result = ValidationSchema.editProfileSchema.validate(
        values,
        { abortEarly: false }
      );
      changeErrors(result.error);

      if (result.error === undefined) {
        await createProfile(
          getAccessTokenSilently,
          values
        );

        resetForm();
        queryClient.invalidateQueries("profile");
      }
    },
  });

  return (
    <div>
      <Dialog
        open={props.isOpen}
        title="Create profile"
        showCloseIcon = {false}
      >
        <Form
          onSubmit={formik.handleSubmit}
        
          saveButton={
            <Button type="submit" buttonType="primary">
              Create
            </Button>
          }
        >
          <Textbox
            placeholder=""
            label="First name"
            id="firstName"
            name="firstName"
            type="text"
            maxLength={maxLengths.firstName}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            required
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "firstName" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder=""
            label="Last name"
            id="lastName"
            name="lastName"
            type="text"
            required
            maxLength={maxLengths.lastName}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={
              !errors
                ? ""
                : cleanString(
                  JSON.stringify(
                    errors.details.map((error) =>
                      error.context.key === "lastName" ? error.message : null
                    )
                  )
                )
              
            }
          />
          <Textbox
            placeholder=""
            label="Email address"
            id="email"
            name="email"
            type="email"
            required
            maxLength={maxLengths.emailAddress}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={
              !errors
                ? ""
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "email"
                          ? error.message
                          : null
                      )
                    )
                  )
            }
          />
        </Form>
      </Dialog>
    </div>
  );
}

export default CreateProfile;
