import React, { useContext } from "react";
import { SessionContext } from "../../context/SessionContext";
import Typography from "carbon-react/lib/components/typography";
import DetailsCard from "./DetailsCard";
import { Roles } from "../../components/Forms/Roles";

function Details(props) {
  const { state: session } = useContext(SessionContext);
  return (
    <div>
      <Typography mb={3} variant="h3">
        Workspace Details
      </Typography>
      <DetailsCard
        developer={props.developerQuery.data}
        editable={session.role === Roles.developerOwner ? true : false}
      />
    </div>
  );
}

export default Details;
