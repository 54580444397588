import React, { useState } from "react";
import { useFormik } from "formik";
import Dialog from "carbon-react/lib/components/dialog";
import Form from "carbon-react/lib/components/form";
import Button from "carbon-react/lib/components/button";
import Textbox from "carbon-react/lib/components/textbox";
import * as ValidationSchema from "./validationSchema.mjs";
import { updateDeveloper } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "react-query";
import * as maxLengths from "./max-lengths"
import { cleanString } from "../common/stringUtils";

function UpdateDeveloper(props) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [errors, changeErrors] = useState(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.developer.name,
      supportEmail: props.developer.supportEmail,
    },
    onSubmit: async (values, { setSubmitting }) => {
      values.id = props.developer.id;
      values.phones = props.developer.phones;
      const result = ValidationSchema.updateDeveloperSchema.validate(values, {
        abortEarly: false,
      });
      changeErrors(result.error);
    
      if (result.error === undefined) {
        const newWorkspace = props.developer;
        newWorkspace.name = values.name;
        newWorkspace.supportEmail = values.supportEmail;
        await updateDeveloper(
          getAccessTokenSilently,
          props.developer.id,
          newWorkspace
        );
        queryClient.invalidateQueries(["developers"]);
        queryClient.invalidateQueries(["developer", props.developer.id]);
        props.setIsOpen(false);
        setSubmitting(false);
        formik.resetForm({});
      }
    },
  });

  const cancelDialog = () => {
    formik.setErrors({});
    formik.resetForm();
    changeErrors(null)
    props.setIsOpen(false);
  };
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onCancel={cancelDialog}
        title="Edit workspace"
      >
        <Form
          onSubmit={formik.handleSubmit}
          leftSideButtons={
            <Button
              buttonType="tertiary"
              onClick={cancelDialog}
            >
              Cancel
            </Button>
          }
          saveButton={
            <Button type="submit" buttonType="primary">
              Save
            </Button>
          }
        >
          <Textbox
            placeholder="Workspace, organization or business name"
            label="Workspace name"
            id="name"
            name="name"
            type="text"
            maxLength={maxLengths.workspaceName}
            onChange={formik.handleChange}
            value={formik.values.name}
            normalize={(value) => (value === "" ? "" : value)}
            required={true}
            error={
              !errors
                ? null
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "name" ? error.message : null
                      )
                    )
                  )
            }
          />
          <Textbox
            placeholder="joe@example.com"
            fieldHelp="We will use this email to contact you regarding this company"
            label="Contact email address"
            id="supportEmail"
            name="supportEmail"
            type="email"
            maxLength={maxLengths.emailAddress}
            onChange={formik.handleChange}
            value={formik.values.supportEmail}
            normalize={(value) => (value === "" ? "" : value)}
            required={true}
            error={
              !errors
                ? null
                : cleanString(
                    JSON.stringify(
                      errors.details.map((error) =>
                        error.context.key === "supportEmail"
                          ? error.message
                          : null
                      )
                    )
                  )
            }
          />
        </Form>
      </Dialog>
    </div>
  );
}

export default UpdateDeveloper;
