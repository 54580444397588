import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import Icon from "carbon-react/lib/components/icon";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(180deg);
}
`;

const Frame = styled.div`
  height: 100vh;
  width: 200px;
  background-color: #ffffff;
  position: fixed;
  display: flex;
  flex-direction: column;
  #active {
    background-color: #a9b1b3;
  }
`;

const SmallFrame = styled.div`
  height: 100vh;
  width: 60px;
  background-color: #ffffff;
  position: fixed;
  display: flex;
  flex-direction: column;
  #active {
    background-color: #a9b1b3;
  }
`;

const Row = styled.li`
  width: auto;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
`;

const SmallRow = styled.li`
  width: 60px;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${Row}:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
  &:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
`;
const IconBox = styled.div`
  flex: 30%;
  display: grid;
  place-items: center;
`;

const RotateIconBox = styled.div`
  flex: 30%;
  display: grid;
  place-items: center;
  ${Row}:hover & {
    animation: ${rotate} 0.2s linear forwards;
  }
  ${SmallRow}:hover & {
    animation: ${rotate} 0.2s linear forwards;
  }
`;

const TitleBox = styled.div`
  flex: 70%;
`;

const CollapseList = styled.div`
  height: auto;
  width: 200px;
  padding: 0;
  margin: 0px;
  position: inherit;
  bottom: 0;
`;

const SmallCollapseList = styled.div`
  height: auto;
  width: 60px;
  padding: 0;
  margin: 0px;
  position: inherit;
  bottom: 0;
`;

function SideBar(props) {
  return (
    <>
      {props.collapse ? (
        <Frame>
          {props.children}
          <CollapseList>
            <Row onClick={() => props.setCollapse(!props.collapse)}>
              <RotateIconBox>
                <Icon type="arrow_left" />
              </RotateIconBox>
              <TitleBox>Collapse</TitleBox>
            </Row>
          </CollapseList>
        </Frame>
      ) : (
        <SmallFrame>
          {props.children}
          <SmallCollapseList>
            <SmallRow onClick={() => props.setCollapse(!props.collapse)}>
              <RotateIconBox>
                <Icon type="arrow_right" />
              </RotateIconBox>
            </SmallRow>
          </SmallCollapseList>
        </SmallFrame>
      )}
    </>
  );
}

export default SideBar;
