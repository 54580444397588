import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Bar from "../Sidebar/index";
import "../../App.css";
import Footer from "../Footer";

const Layout = () => {
  const [collapse, setCollapse] = useState(false);
  useEffect(() => {
    const data = window.localStorage.getItem("COLLAPSED");
    setCollapse(JSON.parse(data));
  }, []);
  useEffect(() => {
    window.localStorage.setItem("COLLAPSED", JSON.stringify(collapse));
  }, [collapse]);
  return (
    <>
      <Header />

      <div>
        {collapse ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "40px",
                bottom: "0",
                left: "0",
                width: "200px",
                backgroundColor: "#ffffff",
                boxShadow: "4px 0 4px -2px #ddd",
              }}
            >
              <Bar
                setCollapse={(collapse) => setCollapse(collapse)}
                collapse={collapse}
              />
            </div>
            <div
              style={{
                paddingTop: "10px",
                paddingLeft: "50px",
                marginLeft: "200px",
                paddingRight: "50px",
                marginTop: "40px",
                overflowY: "auto",
                minHeight: "100vh",
              }}
            >
              <div style={{ padding: "20px" }}>
                <Outlet />
              </div>
            </div>{" "}
            <div
              style={{
                marginLeft: "200px",
                paddingTop: "10px",
                marginTop: "40px",
              }}
            >
              <Footer />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                position: "fixed",
                top: "40px",
                bottom: "0",
                left: "0",
                width: "60px",
                backgroundColor: "#ffffff",
                boxShadow: "4px 0 4px -2px #ddd",
              }}
            >
              <Bar
                setCollapse={(collapse) => setCollapse(collapse)}
                collapse={collapse}
              />
            </div>
            <div
              style={{
                marginLeft: "60px",
                paddingTop: "10px",
                paddingLeft: "50px",
                paddingRight: "50px",
                marginTop: "40px",
                overflowY: "auto",
                minHeight: "100vh",
              }}
            >
              <div style={{ padding: "20px" }}>
                <Outlet />
              </div>
            </div>
            <div
              style={{
                marginLeft: "60px",
                paddingTop: "10px",
                marginTop: "40px",
              }}
            >
              <Footer />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Layout;
