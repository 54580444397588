import React, { createContext, useMemo, useReducer } from "react";

const SessionContext = createContext({});

const sessionReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_DEVELOPER": {
      return { ...state, developerId: payload };
    }

    case "SET_APPLICATION": {
      return { ...state, applicationId: payload };
    }

    case "SET_ROLE": {
      return { ...state, role: payload };
    }

    default: {
      throw new Error();
    }
  }
};

const SessionStore = ({ children }) => {
  const [state, dispatch] = useReducer(sessionReducer, {});

  const memoizedReducer = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <SessionContext.Provider value={memoizedReducer}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionStore, SessionContext };
