import React, { useContext, useState } from "react";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Menu, MenuDivider, MenuItem } from "carbon-react/lib/components/menu";
import { SessionContext } from "../../context/SessionContext";
import CreateDeveloper from "../Forms/CreateDeveloper";

const Row = styled.li`
  width: auto;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SmallRow = styled.li`
  width: 60px;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${Row}:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
  &:hover {
    cursor: pointer;
    background-color: #ccd6db;
  }
`;

const IconBoxCollapsed = styled.div`
  flex: 30%;
  display: grid;
  place-items: center;
`;

const MenuBox = styled.div`
  flex: 100%;
  margin-left: 8px;
  margin-right: 8px;
`;

function WorkspaceSelector({
  index,
  currentDeveloper,
  developersQuery,
  collapse,
  setCollapse
}) {

  const { dispatch } = useContext(SessionContext);
  const [ companyIsOpen, setCompanyIsOpen ] = useState(false);
  
  let location = useLocation();
  const navigate = useNavigate();
  
  const onSelectDeveloper = (developerId) => {
    dispatch({ type: "SET_DEVELOPER", payload: developerId });
    navigate(`/workspaces/${developerId}`);
  };

  const maxWidth="184px"

  return (
    <>
      <CreateDeveloper
        setIsOpen={(companyIsOpen) => setCompanyIsOpen(companyIsOpen)}
        isOpen={companyIsOpen}
      />

      {collapse ? (
        <Row
          key={index}
          id={location.pathname === navigate ? "active" : ""}
        >
          <MenuBox>
            {developersQuery.data && (
              <Menu menuType="white" justifyContent="flex-start" maxWidth={maxWidth}> 
                <MenuItem submenu={currentDeveloper?.name || "Workspaces"} maxWidth={maxWidth}>
                  {developersQuery.data.map((developer) => (
                    <MenuItem
                      key={developer.id}
                      onClick={() => onSelectDeveloper(developer.id)}
                    >
                      {developer.name}
                    </MenuItem>
                  ))}
                  <MenuDivider />
                  <MenuItem
                    icon="add"
                    ariaLabel="Add"
                    onClick={() => setCompanyIsOpen(true)}
                  >
                    Add a new workspace
                  </MenuItem>
                </MenuItem>
              </Menu>
            )}
          </MenuBox>
        </Row>
      ) : (
        <SmallRow
          key={index}
          id={location.pathname === navigate ? "active" : ""}
          onClick={() => {
            setCollapse(true)
          }}
        >
          {" "}
          <IconBoxCollapsed>
            <Icon type="dropdown" />
          </IconBoxCollapsed>
        </SmallRow>
      )}
    </>
  );
}

export default WorkspaceSelector;
